import React from 'react';

const Home = React.lazy(() => import('../pages/Home'));
const Profile = React.lazy(() => import('../pages/Profile'));
const UserPaginas = React.lazy(() => import('../pages/UserPaginas'));
const UserPagina = React.lazy(() => import('../pages/UserPagina'));
const UserCartaoVisita = React.lazy(() => import('../pages/UserCartaoVisita'));
const UserEmail = React.lazy(() => import('../pages/UserEmail'));

const routes = [
  {
    path: '/admin-usuario',
    Component: Home,
  },
  {
    path: '/admin-usuario/perfil',
    Component: Profile,
  },
  {
    path: '/admin-usuario/exibicao/:exibicao/menu/:menu/submenus/:id/paginas',
    Component: UserPaginas,
  },
  {
    path: '/admin-usuario/exibicao/:exibicao/menu/:menu/submenus/:id/pagina',
    Component: UserPagina,
  },
  {
    path: '/admin-usuario/cartao-visitas',
    Component: UserCartaoVisita,
  },
  {
    path: '/admin-usuario/meus-emails',
    Component: UserEmail,
  },
];

export default routes;
