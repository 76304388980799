/* eslint-disable max-len */
import React, { useState, useRef } from 'react';
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Label,
  FormGroup,
} from 'reactstrap';

import { filesize } from 'filesize';
import { uniqueId } from 'lodash';

import FileList from '../../components/DragDrop/FileList';
import Upload from '../../components/DragDrop/Upload';
import GoToTop from '../../components/GoToTop';
import Notification from '../../components/Notification';
import LayoutAdmin from '../../layouts/admin';
import api from '../../services/api';

export default function Diplomacao() {
  const notificationRef = useRef();
  const [message, setMessage] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const updateFile = (fileId, data) => {
    setUploadedFiles((prevState) => {
      const newData = prevState.map((uploadedFile) => (fileId === uploadedFile.id ? { ...uploadedFile, ...data } : uploadedFile));

      return newData;
    });
  };

  const processUpload = (uploadFile) => {
    setMessage('Iniciando importação do arquivo...');
    const data = new FormData();
    data.append('file', uploadFile.file, uploadFile.name);

    api
      .post('/diplomacoes', data, {
        onUploadProgress: (e) => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total), 10);
          console.log('progress upload', progress);
          updateFile(uploadFile.id, { progress });
          if (progress === 100) {
            setMessage('Atualizando dados da diplomação no servidor...');
          }
        },
      })
      .then((response) => {
        const { data: fileData } = response;
        updateFile(uploadFile.id, {
          uploaded: true,
          name: fileData.name,
          filename: fileData.filename,
        });

        notificationRef.current.notify({
          message: 'Dados da diplomação importados com sucesso',
        });
        setMessage('Dados da diplomação atualizado com sucesso');
      })
      .catch((err) => {
        updateFile(uploadFile.id, { error: true });
        setMessage('');
        console.log(err);

        if (err && err.response && err.response.data) {
          const { message: messageError } = err.response.data;
          notificationRef.current.notify({
            message: messageError,
            color: 'danger',
          });

          return;
        }

        notificationRef.current.notify({
          message:
            'Algo inesperado aconteceu e não foi possível carregar os dados da diplomação',
          color: 'danger',
        });
      });
  };

  const handleUpload = (files = []) => {
    const newFiles = files.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    setUploadedFiles([...uploadedFiles, ...newFiles]);

    newFiles.forEach((file) => processUpload(file));
  };

  return (
    <LayoutAdmin>
      <Container>
        <Notification ref={notificationRef} />
        <GoToTop />
        <Row>
          <Col>
            <Card className="mt-5">
              <CardBody>
                <h3>Carregar dados de Diplomação</h3>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="mt-1 shadow">
              <CardBody>
                <FormGroup>
                  <Label>
                    Informe um arquivo CSV com os dados da diplomação
                  </Label>
                  <Upload
                    accept={{
                      'text/csv': [],
                    }}
                    height="100px"
                    onUpload={handleUpload}
                    multiple={false}
                    disabled={!!message}
                  />
                  <FileList files={uploadedFiles} />
                  <p>{message}</p>
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </LayoutAdmin>
  );
}
