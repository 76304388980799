/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useCallback, useContext } from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
  SubMenu,
} from 'react-pro-sidebar';
import { NavLink, useLocation, useParams, useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';

import HTMLReactParser from 'html-react-parser';

import logocurto from '../../../assets/img/logo.png';
import logocompleto from '../../../assets/img/logocompleto.png';
import { AppContext } from '../../../contexts/app';
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';

import './style.css';

function Sidebar({ items }) {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { signOutAdmin } = useAuth();
  const { sidebarShow, toggleSidebar, setLoading } = useContext(AppContext);
  const [collapsedSidebar, setCollapsedSidebar] = useState(false);
  const [fullPageHeight, setFullPageHeight] = useState(0);
  const [menus, setMenus] = useState([]);
  const [exibicao, setExibicao] = useState('');
  const [menu, setMenu] = useState('');

  useEffect(() => {
    const calcFullPageHeight = () => {
      let pageHeight = 0;

      const { body } = document;
      const html = document.documentElement;

      pageHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight,
      );

      setFullPageHeight(pageHeight);
    };

    document.addEventListener('scroll', calcFullPageHeight);

    return () => document.removeEventListener('scroll', calcFullPageHeight, false);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await api.get('/menus');
        setMenus(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, []);

  const handleLogout = useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        navigate('/');
        await signOutAdmin();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div
      id="sidebar"
      style={{
        height: fullPageHeight > 0 ? `${fullPageHeight}px` : '100%',
      }}
    >
      <ProSidebar
        collapsed={collapsedSidebar}
        toggled={sidebarShow === 'closed'}
        breakPoint="md"
        onToggle={toggleSidebar}
      >
        <SidebarHeader className="position-relative">
          <div
            style={{
              padding: '10px 20px',
            }}
          >
            {collapsedSidebar ? (
              <img src={logocurto} alt="safl" height="50px" />
            ) : (
              <img src={logocompleto} alt="safl" width="80%" />
            )}
          </div>
          <Button
            className="position-absolute top-1 left-1"
            color="link"
            size="sm"
            onClick={() => setCollapsedSidebar((prevState) => !prevState)}
          >
            {collapsedSidebar ? (
              <i className="fa-solid fa-plus text-white" />
            ) : (
              <i className="fa-solid fa-minus text-white" />
            )}
          </Button>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="square">
            {items.map(({ to, icon, name }, index) => (
              <MenuItem
                active={
                  location.pathname === to
                  || `${location.pathname}` === `${to}/${params.id}`
                }
                key={index}
                icon={<i className={icon} />}
                title={name}
              >
                {name}
                <NavLink to={to} />
              </MenuItem>
            ))}
            <MenuItem>PORTAL DE CONTEUDO</MenuItem>
            <SubMenu
              title="Forlegis"
              icon={<i className="fa-solid fa-book" />}
              open={exibicao === 'f' || params?.exibicao === 'f'}
              onClick={() => setExibicao('f')}
            >
              {menus
                .filter((m) => m.exibicao === 'f')
                .map((m) => (
                  <SubMenu
                    key={m.id}
                    title={m.nome}
                    icon={<i className="fa-solid fa-circle" />}
                    open={menu == m.id || params?.menu == m.id}
                    onClick={() => setMenu(m.id)}
                  >
                    {m?.submenus?.map((s) => (
                      <MenuItem
                        title={s.nome}
                        key={`${m.id}-${s.id}`}
                        icon={<i className="fa-regular fa-circle-dot" />}
                        active={
                          location.pathname
                            === `/admin/exibicao/${m.exibicao}/menu/${m.id}/submenus/${s.id}/paginas`
                          || location.pathname
                            === `/admin/exibicao/${m.exibicao}/menu/${m.id}/submenus/${s.id}/pagina`
                          || location.pathname
                            === `/admin/exibicao/${m.exibicao}/menu/${m.id}/submenus/${s.id}/cadastro`
                          || location.pathname
                            === `/admin/exibicao/${m.exibicao}/menu/${m.id}/submenus/${s.id}/cadastro/${params.id}`
                          || location.pathname
                            === `/admin/exibicao/${m.exibicao}/menu/${m.id}/submenus/${s.id}/pagina/${params.id}`
                        }
                      >
                        {HTMLReactParser(`<span>${s.nome}</span>`)}
                        <NavLink
                          to={`/admin/exibicao/${m.exibicao}/menu/${m.id}/submenus/${s.id}/paginas`}
                        />
                      </MenuItem>
                    ))}
                  </SubMenu>
                ))}
            </SubMenu>
            <SubMenu
              title="Portal SAFL"
              icon={<i className="fa-solid fa-house-chimney" />}
              open={exibicao === 'p' || params?.exibicao === 'p'}
              onClick={() => setExibicao('p')}
            >
              {menus
                .filter((m) => m.exibicao === 'p')
                .map((m) => (
                  <SubMenu
                    key={m.id}
                    title={m.nome}
                    icon={<i className="fa-solid fa-circle" />}
                    open={menu == m.id || params?.menu == m.id}
                    onClick={() => setMenu(m.id)}
                  >
                    {m?.submenus?.map((s) => (
                      <MenuItem
                        title={s.nome}
                        key={`${m.id}-${s.id}`}
                        icon={<i className="fa-regular fa-circle-dot" />}
                        active={
                          location.pathname
                            === `/admin/exibicao/${m.exibicao}/menu/${m.id}/submenus/${s.id}/paginas`
                          || location.pathname
                            === `/admin/exibicao/${m.exibicao}/menu/${m.id}/submenus/${s.id}/pagina`
                          || location.pathname
                            === `/admin/exibicao/${m.exibicao}/menu/${m.id}/submenus/${s.id}/cadastro`
                          || location.pathname
                            === `/admin/exibicao/${m.exibicao}/menu/${m.id}/submenus/${s.id}/cadastro/${params.id}`
                          || location.pathname
                            === `/admin/exibicao/${m.exibicao}/menu/${m.id}/submenus/${s.id}/pagina/${params.id}`
                        }
                      >
                        {HTMLReactParser(`<span>${s.nome}</span>`)}
                        <NavLink
                          to={`/admin/exibicao/${m.exibicao}/menu/${m.id}/submenus/${s.id}/paginas`}
                        />
                      </MenuItem>
                    ))}
                  </SubMenu>
                ))}
            </SubMenu>
            <MenuItem>DISPOSITIVOS</MenuItem>
            <MenuItem
              active={location.pathname === '/admin/dispositivos'}
              icon={<i className="fa-solid fa-mobile-screen" />}
              title="Dispositivos"
            >
              Dispositivos
              <NavLink to="/admin/dispositivos" />
            </MenuItem>
            <MenuItem>RELATÓRIOS</MenuItem>
            <MenuItem
              active={location.pathname === '/admin/relatorio-presenca'}
              icon={<i className="fa-solid fa-print" />}
              title="Presenças"
            >
              Presenças
              <NavLink to="/admin/relatorio-presenca" />
            </MenuItem>
            <MenuItem
              active={location.pathname === '/admin/relatorio-votacao'}
              icon={<i className="fa-solid fa-print" />}
              title="Votação"
            >
              Votação
              <NavLink to="/admin/relatorio-votacao" />
            </MenuItem>
            <MenuItem
              active={location.pathname === '/'}
              icon={<i className="fa-solid fa-right-to-bracket" />}
              onClick={handleLogout}
            >
              Sair
            </MenuItem>
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </div>
  );
}

export default Sidebar;
