/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  CardFooter,
  Button,
} from 'reactstrap';

import HTMLReactParser from 'html-react-parser';

import GoToTop from '../../components/GoToTop';
import UploadFiles from '../../components/UploadFiles';
import LayoutAdmin from '../../layouts/admin';
import api from '../../services/api';

export default function PaginaNewFiles() {
  const navigate = useNavigate();
  const params = useParams();
  const [menu, setMenu] = useState({});
  const [submenu, setSubmenu] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const { data = {} } = await api.get(`/menus/${params.menu}`);
        setMenu(data);
        setSubmenu(data?.submenus?.find((s) => s.id == params.submenu));
      } catch (error) {
        console.log(error);
      }
    })();
  }, [params]);

  return (
    <LayoutAdmin>
      <Container>
        <GoToTop />
        <Row>
          <Col>
            <Card className="mt-5">
              <CardHeader>
                <div className="d-flex flex-column justify-content-center">
                  <h3>{`Cadastrar Arquivos do(a) ${menu?.nome}`}</h3>
                  <h5 className="font-weight-900 text-primary text-center">
                    {HTMLReactParser(`<span className="text-center">${submenu?.nome}</span>`)}
                  </h5>
                </div>
              </CardHeader>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <UploadFiles
                      accept={{
                        'image/*': [],
                        'video/*': [],
                        'application/pdf': ['.pdf'],
                        'application/vnd.ms-excel': [],
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
                        'application/docx': [],
                        'text/plain': [],
                        'text/csv': [],
                        'application/msword': [],
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
                      }}
                      url="/paginaFiles"
                      parentId={params.id}
                    />
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button
                  color="success"
                  onClick={() => navigate(
                    `/admin/exibicao/${params.exibicao}/menu/${params.menu}/submenus/${params.submenu}/paginas`,
                  )}
                >
                  Finalizar
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </LayoutAdmin>
  );
}
