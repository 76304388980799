import React, {
  useState,
  createContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react';

import Loader from '../components/FullScreenLoader';
import { SIDEBARSHOW } from '../constants/app';
import { useDebounce } from '../hooks/useDebounce';

export const AppContext = createContext();

function AppProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [sidebarShow, setSidebarShow] = useState('opened');
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [navbarTogglerOpen, setNavbarTogglerOpen] = useState(true);
  const searchQuery = useDebounce(search);

  const toggleSidebar = useCallback(() => {
    setSidebarShow((prevState) => {
      localStorage.setItem(SIDEBARSHOW, prevState === 'opened' ? 'closed' : 'opened');
      return prevState === 'opened' ? 'closed' : 'opened';
    });
  }, []);

  useEffect(() => {
    setSidebarShow(localStorage.getItem(SIDEBARSHOW) || 'opened');
  }, []);

  const value = useMemo(
    () => ({
      loading,
      sidebarShow,
      toggleSidebar,
      navbarTogglerOpen,
      setNavbarTogglerOpen,
      setLoading,
      search,
      searchQuery,
      setSearch,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
    }),
    [
      loading,
      sidebarShow,
      toggleSidebar,
      navbarTogglerOpen,
      search,
      searchQuery,
      startDate,
      endDate,
    ],
  );

  return (
    <AppContext.Provider value={value}>
      <Loader loading={loading} />
      {children}
    </AppContext.Provider>
  );
}

export default AppProvider;
