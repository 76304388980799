/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useLocation } from 'react-router-dom';
import {
  Card,
  Container,
  Row,
  Col,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap';

import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import HTMLReactParser from 'html-react-parser';

import Carousel from '../../components/Carousel';
import GoToTop from '../../components/GoToTop';
import ReactPlayerComponent from '../../components/ReactPlayer';
import LayoutAdmin from '../../layouts/admin';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function UserPagina() {
  const location = useLocation();
  const [data, setData] = useState({});
  const [file, setFile] = useState({});
  const [pages, setPages] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setData(location.state.data);
    setFile(location.state.file);
  }, [location.state]);

  return (
    <LayoutAdmin>
      <Container>
        <GoToTop />
        <Row>
          <Col>
            <Card className="mt-5">
              <CardHeader>
                <Row>
                  <Col>
                    <h1 className="font-weight-900 text-primary">
                      {data.titulo}
                    </h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {data.datahora ? (
                      <div>
                        Data:
                        &ensp;
                        {format(new Date(data.datahora), "dd 'de' MMMM 'de' yyyy 'ás' HH:mm:ss", { locale: ptBR })}
                      </div>
                    ) : (
                      data.created_at ? (
                        <div>
                          Cadastrado:
                          &ensp;
                          {format(new Date(data.created_at), "dd 'de' MMMM 'de' yyyy 'ás' HH:mm:ss", { locale: ptBR })}
                        </div>
                      ) : null
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {data.autor ? (
                  <div>
                    Autor:
                    &ensp;
                    {data.autor}
                  </div>
                ) : null}
                {data.situacao ? (
                  <div>
                    Situação:
                    &ensp;
                    {data.situacao}
                  </div>
                ) : null}
                {data.texto ? (
                  <Row>
                    <Col>
                      <div className="text-justify mt-5">
                        {HTMLReactParser(data.texto)}
                      </div>
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Col>
                    {data?.submenu?.tipopagina?.id === 11 ? (
                      data?.files?.length ? (
                        <Carousel items={data.files} />
                      ) : null
                    ) : file ? (
                      file.type === 'pdf' ? (
                        <>
                          <Row>
                            <Col>
                              <div className="d-flex justify-content-center">
                                <a
                                  href={file.file_url}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                >
                                  <Button
                                    className="p-2 px-4 text-white font-weight-500 zoom-105 mb-2"
                                    color="primary"
                                    block
                                  >
                                    Abrir arquivo em nova aba &ensp;
                                    <i className="fas fa-angle-double-right" />
                                  </Button>
                                </a>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {pages && (
                                <Row>
                                  <Col>
                                    <div className="d-flex justify-content-between">
                                      <Button
                                        color="primary"
                                        onClick={() => setPage((p) => (p > 1 ? p - 1 : 1))}
                                      >
                                        {'<'}
                                      </Button>
                                      <small>{`Página ${page} de ${pages}`}</small>
                                      <Button
                                        color="primary"
                                        onClick={() => setPage((p) => (p < pages ? p + 1 : pages))}
                                      >
                                        {'>'}
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              )}
                              <div className="d-flex justify-content-center">
                                <Document
                                  file={file.file_url}
                                  error="Não foi possível carregar o pdf"
                                  loading="Carregando Pdf"
                                  onLoadSuccess={({ numPages }) => {
                                    setPages(numPages);
                                  }}
                                >
                                  <Page pageNumber={page} />
                                </Document>
                              </div>
                              {pages && (
                                <Row>
                                  <Col>
                                    <div className="d-flex justify-content-between">
                                      <Button
                                        color="primary"
                                        onClick={() => setPage((p) => (p > 1 ? p - 1 : 1))}
                                      >
                                        {'<'}
                                      </Button>
                                      <small>{`Página ${page} de ${pages}`}</small>
                                      <Button
                                        color="primary"
                                        onClick={() => setPage((p) => (p < pages ? p + 1 : pages))}
                                      >
                                        {'>'}
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              )}
                            </Col>
                          </Row>
                        </>
                      ) : file.type === 'flv'
                        || file.type === 'mp4'
                        || file.type === 'm3u8'
                        || file.type === 'ts'
                        || file.type === '3gp'
                        || file.type === 'mov'
                        || file.type === 'avi'
                        || file.type === 'wmv' ? (
                          <>
                            <Row>
                              <Col>
                                <div className="d-flex justify-content-center">
                                  <a
                                    href={file.file_url}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                  >
                                    <Button
                                      className="p-2 px-4 text-white font-weight-500 zoom-105 mb-2"
                                      color="primary"
                                      block
                                    >
                                      Abrir arquivo em nova aba &ensp;
                                      <i className="fas fa-angle-double-right" />
                                    </Button>
                                  </a>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <ReactPlayerComponent
                                  url={file.file_url}
                                  playing
                                />
                              </Col>
                            </Row>
                          </>
                        ) : (
                          <a
                            href={file.file_url}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            <Button
                              className="p-2 px-4 text-white font-weight-500 zoom-105 mb-2"
                              color="primary"
                              block
                            >
                              Ver Arquivo &ensp;
                              <i className="fas fa-angle-double-right" />
                            </Button>
                          </a>
                        )
                    ) : null}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </LayoutAdmin>
  );
}
