/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo } from 'react';
import { Button, Card, CardBody, CardFooter, Col, FormGroup, Input, Row, Table, UncontrolledTooltip } from 'reactstrap';

import { useManager } from '../../hooks/manager';
import Pagination from '../Pagination';

function TableManager({
  columns = [],
  actions = [],
  onUpdate,
  onDelete,
}) {
  const {
    datas = [],
    page,
    setPage,
    limit,
    setLimit,
    setSearchArray,
    setSearch,
    hasNextPage,
    hasPrevPage,
    totalPages,
    totalItems,
  } = useManager();

  useEffect(() => {
    setPage(1);
    setSearchArray([]);
  }, []);

  const defaultActions = useMemo(() => [
    {
      name: 'view',
      tooltip: 'Alterar',
      color: 'info',
      className: `${onUpdate ? 'mt-2 mr-2' : 'd-none'}`,
      icon: 'fas fa-edit',
      onClick: (row) => onUpdate(row),
    },
    {
      name: 'delete',
      tooltip: 'Excluir',
      color: 'danger',
      className: `${onDelete ? 'mt-2 mr-2' : 'd-none'}`,
      icon: 'fas fa-trash',
      onClick: (row) => onDelete(row),
    },
  ], []);

  const newActions = useMemo(() => [
    ...defaultActions,
    ...actions,
  ], [defaultActions, actions]);

  const renderActions = (row) => newActions.map((a) => (
    <div key={`${a.name}-${row.id}`}>
      <UncontrolledTooltip target={`button-${a.name}-${row.id}`}>
        {typeof a.tooltip === 'function' ? a.tooltip(row) : a.tooltip}
      </UncontrolledTooltip>
      <Button
        id={`button-${a.name}-${row.id}`}
        color={typeof a.color === 'function' ? a.color(row) : a.color}
        size="sm"
        className={typeof a.className === 'function' ? a.className(row) : a.className}
        onClick={() => a.onClick(row)}
      >
        {typeof a.icon === 'function' ? a.icon(row) : <i className={a.icon} />}
      </Button>
    </div>
  ));

  return (
    <Card>
      <CardBody>
        <Table responsive>
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.dataField} style={{ verticalAlign: 'top' }}>
                  {column.text}
                  {column.textFilter ? (
                    <FormGroup>
                      <Input
                        type="text"
                        bsSize="sm"
                        placeholder={`Buscar ${column.text}`}
                        onChange={({ target: { value } }) => {
                          setSearch({
                            field: column.dataField,
                            value,
                          });
                        }}
                      />
                    </FormGroup>
                  ) : null}
                  {column.selectFilter ? (
                    <FormGroup>
                      <Input
                        type="select"
                        bsSize="sm"
                        onChange={({ target: { value } }) => {
                          setSearch({
                            field: column.dataField,
                            value,
                          });
                        }}
                      >
                        <option value="">Todos</option>
                        {column.selectFilter.map((s) => (
                          <option key={s.value} value={s.value}>
                            {s.label}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  ) : null}
                </th>
              ))}
              <th style={{ verticalAlign: 'top' }}>#</th>
            </tr>
          </thead>
          <tbody>
            {datas.map((row) => (
              <tr key={row.id}>
                {columns.map((column) => (
                  <td key={`${row.id}-${column.dataField}`} align={column?.align ? column.align : 'left'}>
                    {column.formatter
                      ? column.formatter(row)
                      : column.dataField.indexOf('.') > -1
                        ? row[column.dataField.split('.')[0]]?.[
                          column.dataField.split('.')[1]
                        ]
                        : row[column.dataField]}
                  </td>
                ))}
                <td>
                  <div className="d-flex justify-content-end">
                    {renderActions(row)}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
      <CardFooter>
        <Row>
          <Col>
            <FormGroup>
              <Input
                id="limit"
                name="limit"
                type="select"
                value={limit}
                onChange={({ target: { value } }) => setLimit(value)}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </Input>
            </FormGroup>
          </Col>
          <Col>
            <div className="d-flex justify-content-sm-end">
              <Pagination
                page={page}
                hasNextPage={hasNextPage}
                hasPrevPage={hasPrevPage}
                totalPages={totalPages}
                onPageChange={setPage}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <strong>{`Total de resultados: ${totalItems}`}</strong>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
}

export default TableManager;
