import React, { useEffect, useState, useCallback, useRef, useContext } from 'react';
import {
  Card,
  CardBody,
  Container,
  Row,
} from 'reactstrap';

import { filesize } from 'filesize';
import { uniqueId } from 'lodash';

import { AppContext } from '../../contexts/app';
import api from '../../services/api';
import FileList from '../DragDrop/FileList';
import Upload from '../DragDrop/Upload';
import Notification from '../Notification';

export default function UploadFiles({
  url,
  accept,
  label,
  height,
  parentId,
  initialFiles = [],
  cleanUploadedFiles = false,
  onCountFiles,
  onUploadedFiles,
}) {
  const notificationRef = useRef();
  const { setLoading } = useContext(AppContext);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    if (initialFiles.length) {
      setUploadedFiles(
        initialFiles.map((file) => ({
          name: file.name,
          readableSize: file?.size ? filesize(file.size) : 0,
          preview: file.file_url,
          progress: 100,
          uploaded: true,
          error: false,
          url: file.file_url,
          ...file,
        })),
      );
    }
  }, [initialFiles.length]);

  useEffect(() => {
    if (cleanUploadedFiles) {
      setUploadedFiles([]);
    }
  }, [cleanUploadedFiles]);

  const updateFile = (fileId, data) => {
    setUploadedFiles(
      (prevState) => {
        const newData = prevState.map((uploadedFile) => (fileId === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile));

        if (onCountFiles) {
          onCountFiles(newData.filter((uploadedFile) => uploadedFile.error === false).length);
        }

        if (onUploadedFiles) {
          onUploadedFiles(newData);
        }
        return newData;
      },
    );
  };

  const processUpload = (uploadFile) => {
    const data = new FormData();
    data.append('file', uploadFile.file, uploadFile.name);

    if (parentId) {
      data.append('parentId', parentId);
    }

    api
      .post(url, data, {
        onUploadProgress: (e) => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total), 10);
          console.log('progress upload', progress);
          updateFile(uploadFile.id, { progress });
        },
      })
      .then((response) => {
        const { data: fileData } = response;
        updateFile(uploadFile.id, {
          uploaded: true,
          id: fileData.id,
          name: fileData.name,
          filename: fileData.filename,
          url: fileData.file_url,
        });
      })
      .catch(() => {
        updateFile(uploadFile.id, { error: true });
      });
  };

  const handleUpload = (files = []) => {
    const newFiles = files.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    setUploadedFiles([...uploadedFiles, ...newFiles]);

    newFiles.forEach((file) => processUpload(file));
  };

  const handleOnDelete = useCallback((id) => {
    (async () => {
      try {
        setLoading(true);
        await api.delete(`${url}/${id}`);
        setUploadedFiles((fs) => {
          const returnData = fs.filter((value) => value.id !== id);
          if (onCountFiles) {
            onCountFiles(returnData.filter((uploadedFile) => uploadedFile.error === false).length);
          }
          return returnData;
        });
        notificationRef.current.notify({
          message: 'Arquivo excluido com sucesso',
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
        notificationRef.current.notify({
          message: 'Não foi possível excluir a Arquivo',
          color: 'danger',
        });
      }
    })();
  }, []);

  return (
    <Container>
      <Notification ref={notificationRef} />
      <Row>
        <Card className="w-100">
          <CardBody>
            <Upload
              accept={accept}
              message={label}
              height={height}
              onUpload={handleUpload}
            />
            <FileList files={uploadedFiles} onDelete={handleOnDelete} />
          </CardBody>
        </Card>
      </Row>
    </Container>
  );
}
