/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Card,
  Container,
  Row,
  Col,
  CardBody,
  CardFooter,
  Button,
  Spinner,
  UncontrolledTooltip,
} from 'reactstrap';

import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import HTMLReactParser from 'html-react-parser';

import AlertModal from '../../components/AlertModal';
import GoToTop from '../../components/GoToTop';
import Notification from '../../components/Notification';
import Pagination from '../../components/Pagination';
import { AppContext } from '../../contexts/app';
import LayoutAdmin from '../../layouts/admin';
import api from '../../services/api';

export default function UserPaginas() {
  const notificationRef = useRef();
  const confirmRef = useRef();
  const params = useParams();
  const navigate = useNavigate();
  const [datas, setDatas] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const { searchQuery, startDate, endDate } = useContext(AppContext);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (searchQuery || startDate || endDate) {
      console.log('SearchQuery', searchQuery, startDate, endDate);
      setPage(1);
    }
  }, [searchQuery, startDate, endDate]);

  useEffect(
    () => {
      setDatas([]);
      (async () => {
        try {
          setLoading(true);
          let urlString = `/paginas/${params.id}/paginas?page=${page}&size=10`;
          if (searchQuery) {
            urlString = `${urlString}&search=${searchQuery}`;
          }

          if (startDate) {
            urlString = `${urlString}&startDate=${startDate}`;
          }

          if (endDate) {
            urlString = `${urlString}&endDate=${endDate}`;
          }

          // console.log(urlString);
          const res = await api.get(urlString);
          // console.log(res.data.docs);
          setDatas(res.data.docs);
          setHasPrevPage(res.data.hasPrevPage);
          setHasNextPage(res.data.hasNextPage);
          setTotalPages(res.data.totalPages);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      })();
    },
    [page, params, searchQuery, startDate, endDate],
  );

  const handleDelete = useCallback((id) => {
    (async () => {
      try {
        // console.log('destroy', id, url);
        confirmRef.current.show({
          message: 'Tem certeza que deseja excluir?',
          confirmColor: 'danger',
          confirmText: 'Excluir',
          cancelText: 'Cancelar',
          cancelColor: 'link',
          onConfirm: async () => {
            try {
              setLoading(true);
              await api.delete(`/paginas/${id}`);
              setDatas((d) => d.filter((value) => (value.id || value._id) !== id));
              notificationRef.current.notify({
                message: 'Excluido com sucesso',
              });
              confirmRef.current.close();
              setLoading(false);
            } catch (error) {
              console.log(error);
              setLoading(false);
            }
          },
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, []);

  const handleGoToCreate = useCallback(() => {
    navigate(`/admin/exibicao/${params.exibicao}/menu/${params.menu}/submenus/${params.id}/cadastro`);
  }, []);

  const handleGoToUpdate = useCallback((id) => {
    navigate(`/admin/exibicao/${params.exibicao}/menu/${params.menu}/submenus/${params.id}/pagina/${id}`);
  }, []);

  const handleGoToPage = useCallback((id, d, f) => {
    navigate(`/admin/exibicao/${params.exibicao}/menu/${params.menu}/submenus/${id}/pagina`, {
      state: {
        data: d,
        file: f,
      },
    });
  }, []);

  return (
    <LayoutAdmin>
      <AlertModal ref={confirmRef} />
      <Notification ref={notificationRef} />
      <Container>
        <GoToTop />
        {loading ? (
          <Row className="mt-5">
            <Col>
              <div className="d-flex justify-content-center">
                <Spinner type="grow" />
              </div>
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col xs={12} sm={5} md={3} className="mt-5">
                <Button block color="success" onClick={handleGoToCreate}>
                  Cadastrar
                </Button>
              </Col>
            </Row>
            <Row className="mt-3">
              {datas.map((d, i) => (
                <Col key={`${d.id}-${i}`} xs={12} sm={6} className="mb-4">
                  <Card
                    className="shadow"
                    style={{
                      height: '100%',
                      border: '0',
                      borderBottom: '3px solid #dddddd',
                    }}
                  >
                    <CardBody>
                      <h1 className="font-weight-900 text-primary">
                        {d.titulo}
                      </h1>
                      {d.autor ? (
                        <div>
                          Autor:
                          &ensp;
                          {d.autor}
                        </div>
                      ) : null}
                      {d.situacao ? (
                        <div>
                          Situação:
                          &ensp;
                          {d.situacao}
                        </div>
                      ) : null}
                      {d.datahora ? (
                        <div>
                          Data:
                          &ensp;
                          {format(new Date(d.datahora), "dd 'de' MMMM 'de' yyyy 'ás' HH:mm:ss", { locale: ptBR })}
                        </div>
                      ) : null}
                      {d.texto ? (
                        <div>
                          {HTMLReactParser(d.texto.indexOf('<p>') > -1 ? d.texto.split('<p>')[1] : d.texto)}
                        </div>
                      ) : null}
                    </CardBody>
                    <CardFooter>
                      {d?.submenu?.tipopagina?.id === 11 ? (
                        d?.files?.length ? (
                          <Button
                            className="p-2 px-4 text-white font-weight-500 zoom-105 mb-2"
                            color="primary"
                            onClick={() => handleGoToPage(d.submenu.id, d)}
                            block
                          >
                            Ver Fotos &ensp;
                            <i className="fas fa-angle-double-right" />
                          </Button>
                        ) : null
                      ) : (
                        d?.files?.map((f, fi) => (
                          <Button
                            key={`${f.id}-${fi}`}
                            className="p-2 px-4 text-white font-weight-500 zoom-105 mb-2"
                            color="primary"
                            onClick={() => handleGoToPage(d.submenu.id, d, f)}
                            block
                          >
                            Ver Arquivo &ensp;
                            {i + 1}
                            &ensp;
                            <i className="fas fa-angle-double-right" />
                          </Button>
                        ))
                      )}
                      <Row className="mt-3">
                        <Col>
                          <div className="d-flex justify-content-end">
                            <UncontrolledTooltip target="button-update">
                              Alterar
                            </UncontrolledTooltip>
                            <Button
                              id="button-update"
                              color="primary"
                              size="sm"
                              className="mt-2 mr-2"
                              onClick={() => handleGoToUpdate(d.id)}
                            >
                              <i className="fas fa-edit" />
                            </Button>
                            <UncontrolledTooltip target="button-delete">
                              Excluir
                            </UncontrolledTooltip>
                            <Button
                              id="button-delete"
                              color="danger"
                              size="sm"
                              className="mt-2 mr-2"
                              onClick={() => handleDelete(d.id)}
                            >
                              <i className="fas fa-trash" />
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </CardFooter>
                  </Card>
                </Col>
              ))}
            </Row>
            <Row>
              <Col>
                <div className="d-flex justify-content-center">
                  <Pagination
                    page={page}
                    hasNextPage={hasNextPage}
                    hasPrevPage={hasPrevPage}
                    totalPages={totalPages}
                    onPageChange={setPage}
                  />
                </div>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </LayoutAdmin>
  );
}
