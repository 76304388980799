import React, { useEffect, useCallback, useRef, useMemo } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from 'reactstrap';

import Notification from '../../components/Notification';
import TableManager from '../../components/TableManager';
import { useManager } from '../../hooks/manager';
import LayoutAdmin from '../../layouts/admin';

function Admins() {
  const notificationRef = useRef();
  const { load } = useManager();

  useEffect(() => {
    load('/admins');
  }, [load]);

  const imageFormatter = useCallback((row) => (
    row.image ? (
      <img src={row.image_url} alt={row.image_url} width="30px" />
    ) : (
      <span>Sem Imagem</span>
    )
  ), []);

  const columns = useMemo(() => [
    {
      dataField: 'image_url',
      text: 'Imagem',
      formatter: imageFormatter,
    },
    {
      dataField: 'name',
      text: 'Nome',
      textFilter: true,
    },
    {
      dataField: 'cim',
      text: 'CIM',
      textFilter: true,
    },
    {
      dataField: 'nome_loja',
      text: 'Nome Loja',
      textFilter: true,
    },
    {
      dataField: 'nr_loja',
      text: 'Nº Loja',
      textFilter: true,
    },
  ], []);

  return (
    <LayoutAdmin>
      <Notification ref={notificationRef} />
      <Container>
        <Row className="mt-5">
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h3>Administradores</h3>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableManager
              columns={columns}
            />
          </Col>
        </Row>
      </Container>
    </LayoutAdmin>
  );
}

export default Admins;
