/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState, useCallback, useRef, useContext, useMemo } from 'react';
import { PatternFormat } from 'react-number-format';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Progress,
  Row,
  Table,
  UncontrolledTooltip,
} from 'reactstrap';

import { getDate, getMonth, getYear } from 'date-fns';
import * as Yup from 'yup';

import user_tie from '../../assets/img/user_tie.svg';
import DatePickerMaskedInput from '../../components/DatePickerMaskedInput';
import ImageFileInput from '../../components/ImageFileInput';
import Notification from '../../components/Notification';
import Select from '../../components/Select';
import TableManager from '../../components/TableManager';
import { AppContext } from '../../contexts/app';
import { useManager } from '../../hooks/manager';
import LayoutAdmin from '../../layouts/admin';
import api from '../../services/api';
import locationApi from '../../services/api_localidade';

function Users() {
  const notificationRef = useRef();
  const { setLoading } = useContext(AppContext);
  const [validationErrors, setValidationErrors] = useState({});
  const { load, store, update, setDatas } = useManager();
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState({});
  const [user, setUser] = useState({});
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cim, setCim] = useState('');
  const [cpf, setCpf] = useState('');
  const [formattedCpf, setFormattedCpf] = useState('');
  const [data_nascimento, setData_nascimento] = useState('');
  const [telefone_1, setTelefone_1] = useState('');
  const [formattedTelefone_1, setFormattedTelefone_1] = useState('');
  const [lista_funcao, setLista_funcao] = useState('');
  const [role, setRole] = useState();
  const [nome_loja, setNome_loja] = useState('');
  const [nr_loja, setNr_loja] = useState('');
  const [nr_diploma, setNr_diploma] = useState('');
  const [estado, setEstado] = useState('');
  const [cidade, setCidade] = useState('');
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [loadingCidades, setLoadingCidades] = useState(false);
  const [loadingEstados, setLoadingEstados] = useState(false);
  const [logradouro, setLogradouro] = useState('');
  const [inicio_mandato, setInicio_mandato] = useState('');
  const [termino_mandato, setTermino_mandato] = useState('');
  const [image, setImage] = useState('');
  const [sessao, setSessao] = useState('');
  const [sessoes, setSessoes] = useState([]);
  const [roles, setRoles] = useState([]);
  const [showModalLicenciado, setShowModalLicenciado] = useState(false);
  const [licenciado_data_inicio, setLicenciado_data_inicio] = useState('');
  const [licenciado_data_termino, setLicenciado_data_termino] = useState('');
  const [licenciado_observacoes, setLicenciado_observacoes] = useState('');
  const [showModalRenuncia, setShowModalRenuncia] = useState(false);
  const [renuncia_data, setRenuncia_data] = useState(new Date());
  const [renuncia_observacao, setRenuncia_observacao] = useState('');
  const [showModalPc, setShowModalPc] = useState(false);
  const [pc_data, setPc_data] = useState(new Date());
  const [pc_observacao, setPc_observacao] = useState('');
  const [showModalJustificativa, setShowModalJustificativa] = useState(false);
  const [justificativa_observacoes, setJustificativa_observacoes] = useState('');
  const [showModalPresenca, setShowModalPresenca] = useState(false);
  const [showModalReport, setShowModalReport] = useState(false);
  const [presenca_observacoes, setPresenca_observacoes] = useState('');
  const [presencaSessoesFileCRVUrl, setPresencaSessoesFileCRVUrl] = useState('');
  const [presencaSessoesDescricaoLog, setPresencaSessoesDescricaoLog] = useState('');
  const [presencaSessoesProgress, setPresencaSessoesProgress] = useState(0);
  const [results, setResults] = useState([]);
  const [ativo] = useState(-1);
  const [selectedUsers, setSelectedUsers] = useState([{
    label: 'TODOS',
    value: null,
  }]);
  const [selectedSituacoes] = useState([{
    label: 'TODOS',
    value: null,
  }]);
  const [sessoesFilter] = useState([{
    label: 'TODAS',
    value: null,
  }]);

  useEffect(() => {
    load('/users');
  }, [load]);

  const handleUpdate = useCallback((data) => {
    console.log('🚀 ~ handleUpdate ~ data.id:', data.id);
    setValidationErrors({});
    setId(data.id);
    setUser(data);
    setName(data?.name ? data.name : '');
    setCim(data?.cim ? data.cim : '');
    setCpf(data?.cpf ? data.cpf.replace(/[^\d]+/g, '') : '');
    setEmail(data?.email ? data.email : '');
    setTelefone_1(data?.telefone_1 ? data.telefone_1.replace(/[^\d]+/g, '') : '');
    setFormattedTelefone_1(data?.telefone_1);
    setLista_funcao(data?.lista_funcao ? data.lista_funcao : 'Deputado');
    setRole(data?.role?.id ? data.role.id : '');
    setNome_loja(data?.nome_loja ? data.nome_loja : '');
    setNr_loja(data?.nr_loja ? data.nr_loja.replace(/[^\d]+/g, '') : '');
    setNr_diploma(data?.nr_diploma);
    const data_nascimentoSplit = data?.data_nascimento && data?.data_nascimento?.indexOf('/') > -1 ? data.data_nascimento.split('/') : [];
    setData_nascimento(data_nascimentoSplit.length && data_nascimentoSplit[2] && data_nascimentoSplit[1] && data_nascimentoSplit[0]
      ? new Date(
        data_nascimentoSplit[2],
        parseInt(data_nascimentoSplit[1], 10) - 1,
        data_nascimentoSplit[0],
      )
      : '');
    setLogradouro(data?.logradouro ? data.logradouro : '');
    const inicio_mandatoSplit = data?.inicio_mandato && data?.inicio_mandato?.indexOf('/') > -1 ? data.inicio_mandato.split('/') : [];
    setInicio_mandato(inicio_mandatoSplit.length && inicio_mandatoSplit[2] && inicio_mandatoSplit[1] && inicio_mandatoSplit[0]
      ? new Date(
        inicio_mandatoSplit[2],
        parseInt(inicio_mandatoSplit[1], 10) - 1,
        inicio_mandatoSplit[0],
      )
      : '');
    const termino_mandatoSplit = data?.termino_mandato && data?.termino_mandato?.indexOf('/') > -1 ? data.termino_mandato.split('/') : [];
    setTermino_mandato(termino_mandatoSplit.length && termino_mandatoSplit[2] && termino_mandatoSplit[1] && termino_mandatoSplit[0]
      ? new Date(
        termino_mandatoSplit[2],
        parseInt(termino_mandatoSplit[1], 10) - 1,
        termino_mandatoSplit[0],
      )
      : '');
    setImage(data?.image ? data?.image_url : '');
    setShowModal(true);
  }, []);

  const handleCreate = useCallback(() => {
    setValidationErrors({});
    setId('');
    setUser({});
    setName('');
    setCim('');
    setCpf('');
    setEmail('');
    setTelefone_1('');
    setLista_funcao('');
    setRole('');
    setNome_loja('');
    setNr_loja('');
    setNr_diploma('');
    setData_nascimento('');
    setLogradouro('');
    setInicio_mandato('');
    setTermino_mandato('');
    setImage('');
    setShowModal(true);
  }, []);

  const handleCreateLicenciado = useCallback(() => {
    setValidationErrors({});
    setLicenciado_data_inicio('');
    setLicenciado_data_termino('');
    setLicenciado_observacoes('');
    setShowModalLicenciado(true);
  }, []);

  const handleCreateJustificativa = useCallback(() => {
    setValidationErrors({});
    setSessao('');
    setJustificativa_observacoes('');
    setShowModalJustificativa(true);
  }, []);

  const handleCreatePresenca = useCallback(() => {
    setValidationErrors({});
    setSessao('');
    setPresenca_observacoes('PRESENÇA MANUAL');
    setShowModalPresenca(true);
  }, []);

  const handleCreateRenuncia = useCallback(() => {
    setValidationErrors({});
    setRenuncia_data(new Date());
    setRenuncia_observacao('');
    setShowModalRenuncia(true);
  }, []);

  const handleCreatePerdeuCargo = useCallback(() => {
    setValidationErrors({});
    setPc_data(new Date());
    setPc_observacao('');
    setShowModalPc(true);
  }, []);

  const handleReport = useCallback(() => {
    console.log('🚀 ~ handleReport ~ id:', id);
    setValidationErrors({});
    setSelectedUsers([{ value: id }]);
    setShowModalReport(true);
  }, [id]);

  const populateEstados = useCallback(() => {
    if (!estados.length) {
      (async () => {
        try {
          setLoadingEstados(true);
          const { data = [] } = await locationApi.get('estados?orderBy=nome');
          const estadosData = data.map((s) => ({
            value: s?.id,
            label: s?.nome,
            sigla: s?.sigla?.toUpperCase(),
          }));
          setEstados(estadosData);
          setLoadingEstados(false);
        } catch (error) {
          setLoadingEstados(false);
          console.log(error);
        }
      })();
    }
  }, [estados, setLoading]);

  const handleOnChangeEstado = useCallback(
    (estadoValue) => {
      setCidade(null);
      setEstado('');

      console.log('estadoValue', estadoValue);
      if (!estadoValue) {
        setCidades([]);
        return;
      }

      setEstado(estadoValue);

      const estadoId = estadoValue.value;

      console.log('estadoId', estadoId);

      (async () => {
        try {
          setLoadingCidades(true);
          const { data = [] } = await locationApi.get(
            `estados/${estadoId}/municipios`,
          );
          const citiesData = data.map((c) => ({
            value: `${c?.nome}`.toLocaleUpperCase(),
            label: c?.nome,
            regiao: c?.microrregiao?.mesorregiao?.nome,
            ibge: c?.id,
          }));
          setCidades(citiesData);
          setLoadingCidades(false);
        } catch (error) {
          setLoadingCidades(false);
          console.log(error);
        }
      })();
    },
    [estados, setLoadingCidades],
  );

  const handlePresencaSessoesRelatorio = useCallback(() => {
    if (!sessoesFilter?.length) {
      notificationRef.current.notify({
        message: 'Selecione ao menos uma sessão',
        color: 'danger',
      });
      return;
    }

    (async () => {
      try {
        const responseLog = await api.post('/relatorioLogs', {
          descricao: 'Iniciando relatório...',
          finalizado: 0,
        });
        setPresencaSessoesDescricaoLog('Iniciando relatório...');
        setPresencaSessoesProgress(0);
        setResults([]);
        const logId = responseLog.data[0];
        const interval = setInterval(() => {
          api.get(`/relatoriologs/${logId}`)
            .then((response) => {
              const { data } = response;
              setPresencaSessoesDescricaoLog(data.descricao);
              setPresencaSessoesProgress(data.progress);
              if (data.finalizado) {
                console.log('🚀 ~ .then ~ data:', data);
                clearInterval(interval);
                setResults(data.results);
                setPresencaSessoesProgress(100);
                setPresencaSessoesFileCRVUrl(data.file_url);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }, 1000);

        const { data } = await api.get(`/relatorioPresencaBySessoes/${logId}?ids=${JSON.stringify(sessoesFilter.map(({ value }) => value))}&ativo=${ativo}&usersIds=${JSON.stringify(selectedUsers.filter(({ value }) => value).map(({ value }) => value))}&situacoes=${JSON.stringify(selectedSituacoes.filter(({ value }) => value).map(({ value }) => value))}`);
        setPresencaSessoesFileCRVUrl(data.file_csv_url);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [sessoesFilter, ativo, selectedUsers, selectedSituacoes]);

  useEffect(() => {
    if (user?.uf) {
      populateEstados();
    }
  }, [user]);

  useEffect(() => {
    if (estados.length > 0 && user) {
      const e = estados.find(({ sigla }) => sigla === `${user.uf}`.toLocaleUpperCase());
      handleOnChangeEstado(e || '');
    }
  }, [estados, estados.length, user]);

  useEffect(() => {
    if (cidades.length > 0 && user) {
      const c = cidades.find(({ value }) => value === `${user?.cidade}`.toLocaleUpperCase());
      setCidade(c?.value ? { value: c?.value?.toLocaleUpperCase(), label: c?.label } : '');
    }
  }, [cidades, cidades.length, user]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data = [] } = await api.get('/sessoes');
        setSessoes(
          data
            .filter((d) => !d.is_teste)
            .map((d) => ({
              value: d.id,
              label: `${d.id} - ${d.nome}`,
            })),
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();

    return () => setSessoes([]);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data = [] } = await api.get('/roles?size=0');
        setRoles(
          data
            .map((d) => ({
              value: d.id,
              label: `${d.display_name}`,
            })),
        );
        /* [
          {
            label: 'Deputado',
            value: 'Deputado',
          },
          {
            label: 'Venerável',
            value: 'Venerável',
          },
          {
            label: 'Deputado Honorário',
            value: 'Deputado Honorário',
          },
          {
            label: 'Presidente de PAEL',
            value: 'Presidente de PAEL',
          },
          {
            label: 'Grão Mestre',
            value: 'Grão Mestre',
          },
          {
            label: 'Irmão',
            value: 'Irmão',
          },
        ] */
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();

    return () => setRoles([]);
  }, []);

  const handleSave = async () => {
    try {
      const data = {
        name,
        email,
        cim,
        cpf,
        telefone_1,
        nome_loja,
        nr_loja,
        nr_diploma,
        role_id: role,
        data_nascimento: data_nascimento
          ? `${
            getDate(data_nascimento) < 10
              ? `0${getDate(data_nascimento)}`
              : getDate(data_nascimento)
          }/${
            getMonth(data_nascimento) + 1 < 10
              ? `0${getMonth(data_nascimento) + 1}`
              : getMonth(data_nascimento) + 1
          }/${getYear(data_nascimento)}`
          : '',
        estado: estado ? estado.sigla : '',
        cidade: cidade ? cidade.value : '',
        inicio_mandato: inicio_mandato
          ? `${
            getDate(inicio_mandato) < 10
              ? `0${getDate(inicio_mandato)}`
              : getDate(inicio_mandato)
          }/${
            getMonth(inicio_mandato) + 1 < 10
              ? `0${getMonth(inicio_mandato) + 1}`
              : getMonth(inicio_mandato) + 1
          }/${getYear(inicio_mandato)}`
          : '',
        termino_mandato: termino_mandato
          ? `${
            getDate(termino_mandato) < 10
              ? `0${getDate(termino_mandato)}`
              : getDate(termino_mandato)
          }/${
            getMonth(termino_mandato) + 1 < 10
              ? `0${getMonth(termino_mandato) + 1}`
              : getMonth(termino_mandato) + 1
          }/${getYear(termino_mandato)}`
          : '',
      };

      const schema = Yup.object().shape({
        name: Yup.string().required('Informe o nome completo do deputado'),
        cim: Yup.string().required('Informe o CIM'),
        role_id: Yup.string().required('Selecione o Cargo'),
        // cpf: Yup.string().required('Informe o CPF'),
        /* email: Yup.string()
          .email('E-mail inválido')
          .required('Informe um email'), */
        // telefone_1: Yup.string().required('Informe um telefone'),
        nome_loja: Yup.string().required('Informe o nome da loja'),
        nr_loja: Yup.string().required('Informe o número da loja'),
        nr_diploma: Yup.string().required('Informe o número do diploma'),
        // data_nascimento: Yup.string().required('Informe uma data de nascimento'),
        estado: Yup.string().required('Selecione o oriente da loja'),
        cidade: Yup.string().required('Selecione a cidade da loja'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setLoading(true);
      const formData = new FormData();

      formData.append('name', name);
      formData.append('cim', cim);
      formData.append('cpf', formattedCpf);
      formData.append('data_nascimento', data.data_nascimento);
      formData.append('email', email);
      formData.append('telefone_1', formattedTelefone_1);
      formData.append('nome_loja', nome_loja);
      formData.append('lista_funcao', lista_funcao);
      formData.append('role_id', role);
      formData.append('nr_loja', nr_loja);
      formData.append('nr_diploma', nr_diploma);
      formData.append('uf_loja', data.estado);
      formData.append('uf', data.estado);
      formData.append('cidade', data.cidade);
      formData.append('logradouro', logradouro);
      formData.append('inicio_mandato', data.inicio_mandato);
      formData.append('termino_mandato', data.termino_mandato);

      if (image instanceof File) {
        formData.append('image', image);
      }

      if (id) {
        update(id, '/users', formData, () => {
          setLoading(false);
          setShowModal(false);
        }, () => {
          setLoading(false);
        });
        return;
      }

      store('/users', formData, () => {
        setLoading(false);
        setShowModal(false);
      }, () => {
        setLoading(false);
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
      setValidationErrors({});
      // Validation failed
      if (err instanceof Yup.ValidationError) {
        notificationRef.current.notify({
          message:
            'Por favor, preencha todos os dados necessários para salvar',
          color: 'warning',
        });
        const ve = {};
        err.inner.forEach((e) => {
          ve[e.path] = e.message;
        });
        setValidationErrors(ve);
        return;
      }

      if (err && err.response && err.response.data) {
        const { message } = err.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });

        return;
      }

      notificationRef.current.notify({
        message: 'Algo inesperado aconteceu e não foi possível salvar',
        color: 'danger',
      });
    }
  };

  const handleActive = useCallback((userId, userAtivo) => {
    try {
      setLoading(true);
      const formData = new FormData();

      formData.append('ativo', userAtivo);

      update(userId, '/users', formData, () => {
        setLoading(false);
      }, () => {
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      console.log(error);

      if (error && error.response && error.response.data) {
        const { message } = error.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });

        return;
      }

      notificationRef.current.notify({
        message: 'Algo inesperado aconteceu e não foi possível salvar',
        color: 'danger',
      });
    }
  }, []);

  const handleSaveLicenciado = useCallback(() => {
    (async () => {
      try {
        const data = {
          licenciado_data_inicio,
          licenciado_data_termino,
          licenciado_observacoes,
        };

        const schema = Yup.object().shape({
          licenciado_data_inicio: Yup.string().required('Informe a data de início'),
          licenciado_data_termino: Yup.string().required('Informe a data de término'),
          licenciado_observacoes: Yup.string().required('Informe a observações'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        await api.post('/licenciados', {
          id_usuario: id,
          data_inicio: licenciado_data_inicio,
          data_termino: licenciado_data_termino,
          observacoes: licenciado_observacoes,
        });

        notificationRef.current.notify({
          message: 'Usuário Licenciado com sucesso',
        });

        setLoading(false);
        setShowModalLicenciado(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message:
            'Por favor, preencha todos os dados necessários para salvar',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notificationRef.current.notify({
            message,
            color: 'danger',
          });

          return;
        }

        notificationRef.current.notify({
          message: 'Algo inesperado aconteceu e não foi possível salvar',
          color: 'danger',
        });
      }
    })();
  }, [id, licenciado_data_inicio, licenciado_data_termino, licenciado_observacoes]);

  const handleSaveJustificativa = useCallback(() => {
    (async () => {
      try {
        const data = {
          sessao: sessao ? sessao.value : '',
          justificativa_observacoes,
        };

        const schema = Yup.object().shape({
          sessao: Yup.string().required('Informe a sessão'),
          justificativa_observacoes: Yup.string().required('Informe a observações'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        await api.post('/justificativas', {
          id_usuario: id,
          id_sessao: data.sessao,
          observacoes: justificativa_observacoes,
        });

        notificationRef.current.notify({
          message: 'Usuário justificado com sucesso',
        });

        setLoading(false);
        setShowModalJustificativa(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message:
            'Por favor, preencha todos os dados necessários para salvar',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notificationRef.current.notify({
            message,
            color: 'danger',
          });

          return;
        }

        notificationRef.current.notify({
          message: 'Algo inesperado aconteceu e não foi possível salvar',
          color: 'danger',
        });
      }
    })();
  }, [id, sessao, justificativa_observacoes]);

  const handleSavePresenca = useCallback(() => {
    (async () => {
      try {
        const data = {
          sessao: sessao ? sessao.value : '',
          presenca_observacoes,
        };

        const schema = Yup.object().shape({
          sessao: Yup.string().required('Informe a sessão'),
          presenca_observacoes: Yup.string().required('Informe a observações'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        await api.post('/presencas', {
          id_usuario: id,
          id_sessao: data.sessao,
          observacoes: presenca_observacoes,
        });

        notificationRef.current.notify({
          message: 'Presença do usuário registrada com sucesso',
        });

        setLoading(false);
        setShowModalPresenca(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message:
            'Por favor, preencha todos os dados necessários para salvar',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notificationRef.current.notify({
            message,
            color: 'danger',
          });

          return;
        }

        notificationRef.current.notify({
          message: 'Algo inesperado aconteceu e não foi possível salvar',
          color: 'danger',
        });
      }
    })();
  }, [id, sessao, presenca_observacoes]);

  const handleSaveRenuncia = useCallback(() => {
    (async () => {
      try {
        const data = {
          renuncia_data,
          renuncia_observacao,
        };

        const schema = Yup.object().shape({
          renuncia_data: Yup.string().required('Informe a data da renúncia'),
          renuncia_observacao: Yup.string().required('Informe uma observação'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        const { data: { user: userData } } = await api.post('/renuncias', {
          id_usuario: id,
          data: renuncia_data,
          observacao: renuncia_observacao,
        });

        setDatas((datas = []) => datas.map((u) => ((u.id || u._id) === id ? userData : u)));

        notificationRef.current.notify({
          message: 'Renuncia cadastrado com sucesso',
        });

        setLoading(false);
        setShowModalRenuncia(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message:
              'Por favor, preencha todos os dados necessários para salvar',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notificationRef.current.notify({
            message,
            color: 'danger',
          });

          return;
        }

        notificationRef.current.notify({
          message: 'Algo inesperado aconteceu e não foi possível salvar',
          color: 'danger',
        });
      }
    })();
  }, [id, renuncia_data, renuncia_observacao]);

  const handleSavePc = useCallback(() => {
    (async () => {
      try {
        const data = {
          pc_data,
          pc_observacao,
        };

        const schema = Yup.object().shape({
          pc_data: Yup.string().required('Informe a data'),
          pc_observacao: Yup.string().required('Informe uma observação'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        const { data: { user: userData } } = await api.post('/userEvents', {
          id_usuario: id,
          tipo: 'perdeucargo',
          data: pc_data,
          observacao: pc_observacao,
        });

        setDatas((datas = []) => datas.map((u) => ((u.id || u._id) === id ? userData : u)));

        notificationRef.current.notify({
          message: 'Cadastrado com sucesso',
        });

        setLoading(false);
        setShowModalPc(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message:
              'Por favor, preencha todos os dados necessários para salvar',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notificationRef.current.notify({
            message,
            color: 'danger',
          });

          return;
        }

        notificationRef.current.notify({
          message: 'Algo inesperado aconteceu e não foi possível salvar',
          color: 'danger',
        });
      }
    })();
  }, [id, pc_data, pc_observacao]);

  const imageFormatter = useCallback((row) => (
    row.image ? (
      <img src={row.image_url} alt={row.image_url} width="50px" />
    ) : (
      <img src={user_tie} alt="sem img" width="50px" />
    )
  ), []);

  const ativoFormatter = useCallback((row) => (
    <div className="w-100 d-flex justify-content-center">
      <UncontrolledTooltip target={`status-${row?.id}`}>
        {`Controla situação do deputado: Atualmente ${row?.ativo ? 'Ativo' : 'Inativo'}`}
      </UncontrolledTooltip>
      <Button
        id={`status-${row?.id}`}
        color="light"
        size="sm"
        onClick={() => handleActive(row?.id, !row?.ativo)}
        block
      >
        {row?.ativo ? 'Inativar' : 'Ativar'}
      </Button>
    </div>
  ), []);

  const acessoForlegisFormatter = useCallback((row) => (
    <div className="w-100 d-flex justify-content-center">
      <UncontrolledTooltip target={`acesso-forlegis-${row?.id}`}>
        { row?.role?.has_access_forlegis ? 'Tem acesso Forlegis' : 'Não tem acesso Forlegis' }
      </UncontrolledTooltip>
      {row?.role?.has_access_forlegis ? <i id={`acesso-forlegis-${row?.id}`} className="fa-solid fa-circle-check text-success" /> : <i id={`acesso-forlegis-${row?.id}`} className="fa-solid fa-circle-xmark text-danger" />}
      {/* <Button
        id={`acesso-forlegis-${row?.id}`}
        color="light"
        size="sm"
        onClick={() => update(row?.id, '/users', { acesso_forlegis: !row?.acesso_forlegis })}
        block
      >
        {row?.acesso_forlegis ? 'Tirar Acesso' : 'Dar Acesso'}
      </Button> */}
    </div>
  ), []);

  const acessoPortalFormatter = useCallback((row) => (
    <div className="w-100 d-flex justify-content-center">
      <UncontrolledTooltip target={`acesso-portal-${row?.id}`}>
        { row?.role?.has_access_portal ? 'Tem acesso Portal' : 'Não tem acesso Portal' }
      </UncontrolledTooltip>
      {row?.role?.has_access_portal ? <i id={`acesso-portal-${row?.id}`} className="fa-solid fa-circle-check text-success" /> : <i id={`acesso-portal-${row?.id}`} className="fa-solid fa-circle-xmark text-danger" />}
      {/* <Button
        id={`acesso-forlegis-${row?.id}`}
        color="light"
        size="sm"
        onClick={() => update(row?.id, '/users', { acesso_forlegis: !row?.acesso_forlegis })}
        block
      >
        {row?.acesso_forlegis ? 'Tirar Acesso' : 'Dar Acesso'}
      </Button> */}
    </div>
  ), []);

  const acessoListadeputadosFormatter = useCallback(
    (row) => (
      <div className="w-100 d-flex justify-content-center">
        <UncontrolledTooltip target={`acesso-listadeputados-${row?.id}`}>
          {row?.role?.has_access_list_contact ? 'Tem acesso Lista Deputados' : 'Não tem acesso Lista Deputados' }
        </UncontrolledTooltip>
        {row?.role?.has_access_list_contact ? <i id={`acesso-listadeputados-${row?.id}`} className="fa-solid fa-circle-check text-success" /> : <i id={`acesso-listadeputados-${row?.id}`} className="fa-solid fa-circle-xmark text-danger" />}
        {/* <Button
          id={`acesso-listadeputados-${row?.id}`}
          color="light"
          size="sm"
          onClick={() => update(row?.id, '/users', {
            acesso_listadeputados: !row?.acesso_listadeputados,
          })}
          block
        >
          {row?.acesso_listadeputados ? 'Tirar da Lista' : 'Listar'}
        </Button> */}
      </div>
    ),
    [],
  );

  const aptoVotarFormatter = useCallback((row) => (
    <div className="w-100 d-flex justify-content-center">
      <UncontrolledTooltip target={`apto-votar-${row?.id}`}>
        {`Controla se o deputado esta apto a Votar: Atualmente ${row?.apto_votar ? 'Esta apto a votar' : 'Não esta apto a votar'}`}
      </UncontrolledTooltip>
      {row?.apto_votar ? <i id={`apto-votar-${row?.id}`} className="fa-solid fa-circle-check text-success" /> : <i id={`apto-votar-${row?.id}`} className="fa-solid fa-circle-xmark text-danger" />}
      {/* <Button
        id={`apto-votar-${row?.id}`}
        color="light"
        size="sm"
        onClick={() => update(row?.id, '/users', { apto_votar: !row?.apto_votar })}
        block
      >
        {row?.apto_votar ? 'Reprovar' : 'Aprovar'}
      </Button> */}
    </div>
  ), []);

  const acessoAppFormatter = useCallback((row) => (
    <div className="w-100 d-flex justify-content-center">
      <UncontrolledTooltip target={`app-${row?.id}`}>
        { row?.role?.has_access_app ? 'Tem acesso ao Aplicativo' : 'Não tem acesso ao Aplicativo' }
      </UncontrolledTooltip>
      {row?.role?.has_access_app ? <i id={`app-${row?.id}`} className="fa-solid fa-circle-check text-success" /> : <i id={`app-${row?.id}`} className="fa-solid fa-circle-xmark text-danger" />}
      {/* <Button
        id={`apto-votar-${row?.id}`}
        color="light"
        size="sm"
        onClick={() => update(row?.id, '/users', { apto_votar: !row?.apto_votar })}
        block
      >
        {row?.apto_votar ? 'Reprovar' : 'Aprovar'}
      </Button> */}
    </div>
  ), []);

  const renunciaFormatter = useCallback((row) => (
    <div className="w-100 d-flex justify-content-center">
      {row?.renunciado ? 'Renunciou' : 'Não Renunciou'}
    </div>
  ), []);

  const perdeuCargoFormatter = useCallback((row) => (
    <div className="w-100 d-flex justify-content-center">
      {row?.perdeucargo ? 'Perdeu' : 'Não Perdeu'}
    </div>
  ), []);

  const isValidEmailFormatter = useCallback((row) => (
    <div className="w-100 d-flex justify-content-center">
      {row?.is_valid_email ? <i className="fa-solid fa-circle-check text-success" /> : <i className="fa-solid fa-circle-xmark text-danger" />}
    </div>
  ), []);

  const ativoSelectFilter = useMemo(
    () => [
      {
        value: true,
        label: 'Ativo',
      },
      {
        value: false,
        label: 'Inativo',
      },
    ],
    [],
  );

  const isValidEmailSelectFilter = useMemo(
    () => [
      {
        value: true,
        label: 'Emails Válidos',
      },
      {
        value: false,
        label: 'Emails Inválidos',
      },
    ],
    [],
  );

  const acessoForlegisSelectFilter = useMemo(
    () => [
      {
        value: true,
        label: 'Com Acesso',
      },
      {
        value: false,
        label: 'Sem Acesso',
      },
    ],
    [],
  );

  const acessoPortalSelectFilter = useMemo(
    () => [
      {
        value: true,
        label: 'Com Acesso',
      },
      {
        value: false,
        label: 'Sem Acesso',
      },
    ],
    [],
  );

  const acessoAppSelectFilter = useMemo(
    () => [
      {
        value: true,
        label: 'Com Acesso',
      },
      {
        value: false,
        label: 'Sem Acesso',
      },
    ],
    [],
  );

  const acessoListadeputadosSelectFilter = useMemo(
    () => [
      {
        value: true,
        label: 'Lista Deputado',
      },
      {
        value: false,
        label: 'Não Lista Deputado',
      },
    ],
    [],
  );

  const aptoVotarSelectFilter = useMemo(
    () => [
      {
        value: true,
        label: 'Aprovados',
      },
      {
        value: false,
        label: 'Não Aprovados',
      },
    ],
    [],
  );

  const renunciadosSelectFilter = useMemo(
    () => [
      {
        value: true,
        label: 'Renunciou',
      },
      {
        value: false,
        label: 'Não Renunciou',
      },
    ],
    [],
  );

  const perdeuCargosSelectFilter = useMemo(
    () => [
      {
        value: true,
        label: 'Perdeu Cargo',
      },
      {
        value: false,
        label: 'Não Perdeu Cargo',
      },
    ],
    [],
  );

  const columns = useMemo(() => [
    {
      dataField: 'image_url',
      text: 'Imagem',
      formatter: imageFormatter,
    },
    {
      dataField: 'name',
      text: 'Nome',
      textFilter: true,
    },
    {
      dataField: 'cim',
      text: 'CIM',
      textFilter: true,
    },
    {
      dataField: 'nome_loja',
      text: 'Nome Loja',
      textFilter: true,
    },
    {
      dataField: 'nr_loja',
      text: 'Loja',
      textFilter: true,
    },
    {
      dataField: 'lista_funcao',
      text: 'Cargo',
      selectFilter: roles,
    },
    {
      dataField: 'ativo',
      text: 'Ativo',
      formatter: ativoFormatter,
      selectFilter: ativoSelectFilter,
    },
    {
      dataField: 'renunciado',
      text: 'Renunciou',
      formatter: renunciaFormatter,
      selectFilter: renunciadosSelectFilter,
    },
    {
      dataField: 'perdeucargo',
      text: 'Perdeu?',
      formatter: perdeuCargoFormatter,
      selectFilter: perdeuCargosSelectFilter,
    },
    {
      dataField: 'acesso_forlegis',
      text: 'Forlegis',
      formatter: acessoForlegisFormatter,
      selectFilter: acessoForlegisSelectFilter,
    },
    {
      dataField: 'acesso_portal',
      text: 'Portal',
      formatter: acessoPortalFormatter,
      selectFilter: acessoPortalSelectFilter,
    },
    {
      dataField: 'acesso_app',
      text: 'App',
      formatter: acessoAppFormatter,
      selectFilter: acessoAppSelectFilter,
    },
    {
      dataField: 'apto_votar',
      text: 'Apto',
      formatter: aptoVotarFormatter,
      selectFilter: aptoVotarSelectFilter,
    },
    {
      dataField: 'acesso_listadeputados',
      text: 'Lista Deputado',
      formatter: acessoListadeputadosFormatter,
      selectFilter: acessoListadeputadosSelectFilter,
    },
    {
      dataField: 'is_valid_email',
      text: 'Email',
      formatter: isValidEmailFormatter,
      selectFilter: isValidEmailSelectFilter,
    },
  ], [roles]);

  const actions = useMemo(
    () => [
      {
        name: 'type',
        tooltip: (row) => (row?.type === 'admin'
          ? 'Não Liberar acesso Admin'
          : 'Liberar acesso Admin'),
        color: (row) => (row?.type === 'admin' ? 'danger' : 'success'),
        className: 'mt-2 mr-2 text-white',
        icon: 'fa-solid fa-user-gear',
        onClick: (row) => update(row?.id, '/users', {
          type: row.type === 'admin' ? 'user' : 'admin',
        }),
      },
    ],
    [],
  );

  const renderResults = useCallback((index, keys = [], datas = []) => (
    <Table className="mb-5" bordered size="sm">
      <thead>
        <tr>
          <th style={{ backgroundColor: '#c1c1c1' }} width="300px">{keys[0]}</th>
          <th style={{ backgroundColor: '#f1f1f1', textAlign: 'right' }}>{datas[index][keys[0]]}</th>
        </tr>
      </thead>
      <tbody>
        {keys.slice(1, keys.length - 1).map((key) => (
          <tr>
            <td style={{ backgroundColor: '#c1c1c1' }} width="300px">{key}</td>
            <td style={{ textAlign: 'right' }}>{datas[index][key]}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  ));

  return (
    <LayoutAdmin>
      <Notification ref={notificationRef} />
      <Container fluid>
        <Row className="mt-5">
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h3>Usuários</h3>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col>
                    <Row>
                      <Col xs={12} sm={5} md={3} className="mb-3 mb-xl-0">
                        <Button block color="success" onClick={handleCreate}>
                          Cadastrar
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableManager
              columns={columns}
              actions={actions}
              onUpdate={(row) => handleUpdate(row)}
              // onDelete={(row) => destroy(row._id, '/users')}
            />
          </Col>
        </Row>
        <Modal
          isOpen={showModal}
          size="lg"
          toggle={() => setShowModal(!showModal)}
          centered
        >
          <ModalHeader>
            <strong>Usuário</strong>
            <Button
              className="close position-absolute top-4 right-4 py-1 px-2"
              onClick={() => setShowModal(false)}
            >
              <i className="fas fa-times" />
            </Button>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <ImageFileInput previewUrl={image} onChange={setImage} />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <FormGroup>
                  <Label for="name">Nome Completo</Label>
                  <InputGroup
                    className={`input-group-merge ${
                      validationErrors.name ? 'invalid' : ''
                    }`}
                  >
                    <Input
                      id="name"
                      type="text"
                      placeholder="Nome Completo"
                      value={name}
                      invalid={!!validationErrors.name}
                      onChange={({ target: { value } }) => setName(value)}
                    />
                    <InputGroupText>
                      <i className="fas fa-file-signature" />
                    </InputGroupText>
                    <FormFeedback>{validationErrors.name}</FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="cim">CIM</Label>
                  <InputGroup
                    className={`input-group-merge ${
                      validationErrors.cim ? 'invalid' : ''
                    }`}
                  >
                    <Input
                      id="cim"
                      type="text"
                      placeholder="CIM"
                      value={cim}
                      invalid={!!validationErrors.cim}
                      onChange={({ target: { value } }) => setCim(value)}
                    />
                    <InputGroupText>
                      <i className="fa-solid fa-address-card" />
                    </InputGroupText>
                    <FormFeedback>{validationErrors.cim}</FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <FormGroup>
                  <Label for="cpf">CPF</Label>
                  <InputGroup
                    className={`input-group-merge ${
                      validationErrors.cpf ? 'invalid' : ''
                    }`}
                  >
                    <PatternFormat
                      id="cpf"
                      customInput={Input}
                      format="###.###.###-##"
                      mask="_"
                      valueIsNumericString
                      type="text"
                      placeholder="CPF"
                      value={cpf}
                      invalid={!!validationErrors.cpf}
                      onValueChange={({ value, formattedValue }) => {
                        setCpf(value);
                        setFormattedCpf(formattedValue);
                      }}
                    />
                    <InputGroupText>
                      <i className="fa-solid fa-arrow-down-1-9" />
                    </InputGroupText>
                    <FormFeedback>{validationErrors.cpf}</FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs={6}>
                <DatePickerMaskedInput
                  title="Data de Nascimento"
                  label="Data de Nascimento"
                  selected={data_nascimento}
                  invalidMessage={validationErrors.data_nascimento}
                  onChange={(date) => setData_nascimento(date)}
                  openToDate={data_nascimento || new Date(1990, 0, 1)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <InputGroup
                    className={`input-group-merge ${
                      validationErrors.email ? 'invalid' : ''
                    }`}
                  >
                    <Input
                      id="email"
                      type="email"
                      placeholder="Email"
                      value={email}
                      invalid={!!validationErrors.email}
                      onChange={({ target: { value } }) => setEmail(value)}
                    />
                    <InputGroupText>
                      <i className="fas fa-envelope" />
                    </InputGroupText>
                    <FormFeedback>{validationErrors.email}</FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="telefone_1">Telefone (Celular)</Label>
                  <InputGroup
                    className={`input-group-merge ${
                      validationErrors.telefone_1 ? 'invalid' : ''
                    }`}
                  >
                    <PatternFormat
                      id="telefone_1"
                      customInput={Input}
                      format="(##) #####-####"
                      mask="_"
                      valueIsNumericString
                      type="text"
                      placeholder="telefone"
                      value={telefone_1}
                      invalid={!!validationErrors.telefone_1}
                      onValueChange={({ value, formattedValue }) => {
                        setTelefone_1(value);
                        setFormattedTelefone_1(formattedValue);
                      }}
                    />
                    <InputGroupText>
                      <i className="fa-solid fa-mobile-screen" />
                    </InputGroupText>
                    <FormFeedback>{validationErrors.telefone_1}</FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="role">Cargo</Label>
                  <InputGroup className={`input-group-merge ${validationErrors.role_id ? 'invalid' : ''
                  }`}
                  >
                    <Input
                      id="role"
                      type="select"
                      placeholder="Cargo"
                      value={role}
                      invalid={!!validationErrors.role_id}
                      onChange={({ target: { value } }) => {
                        setRole(value);
                        setLista_funcao(roles?.find?.((r) => r.id === value)?.display_name);
                      }}
                    >
                      <option value="">Selecione umo Cargo</option>
                      {roles.map(({ value, label }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </Input>
                    <InputGroupText>
                      <i className="fa-solid fa-user-tag" />
                    </InputGroupText>
                    <FormFeedback>{validationErrors.role_id}</FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <FormGroup>
                  <Label for="nome_loja">Nome da Loja</Label>
                  <InputGroup
                    className={`input-group-merge ${
                      validationErrors.nome_loja ? 'invalid' : ''
                    }`}
                  >
                    <Input
                      id="nome_loja"
                      type="text"
                      placeholder="Nome da Loja"
                      value={nome_loja}
                      invalid={!!validationErrors.nome_loja}
                      onChange={({ target: { value } }) => setNome_loja(value)}
                    />
                    <InputGroupText>
                      <i className="fa-solid fa-store" />
                    </InputGroupText>
                    <FormFeedback>{validationErrors.nome_loja}</FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="nr_loja">Número da Loja</Label>
                  <InputGroup
                    className={`input-group-merge ${
                      validationErrors.nr_loja ? 'invalid' : ''
                    }`}
                  >
                    <Input
                      id="nr_loja"
                      type="number"
                      placeholder="Número da Loja"
                      value={nr_loja}
                      invalid={!!validationErrors.nr_loja}
                      onChange={({ target: { value } }) => setNr_loja(value)}
                    />
                    <InputGroupText>
                      <i className="fa-solid fa-arrow-down-1-9" />
                    </InputGroupText>
                    <FormFeedback>{validationErrors.nr_loja}</FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="nr_diploma">Número do Diploma</Label>
                  <InputGroup
                    className={`input-group-merge ${
                      validationErrors.nr_diploma ? 'invalid' : ''
                    }`}
                  >
                    <Input
                      id="nr_diploma"
                      type="number"
                      placeholder="Número do Diploma"
                      value={nr_diploma}
                      invalid={!!validationErrors.nr_diploma}
                      onChange={({ target: { value } }) => setNr_diploma(value)}
                    />
                    <InputGroupText>
                      <i className="fa-solid fa-certificate" />
                    </InputGroupText>
                    <FormFeedback>{validationErrors.nr_diploma}</FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Label>Estado da Loja</Label>
                <Select
                  placeholder="Estado da Loja"
                  value={estado}
                  options={estados}
                  loading={loadingEstados}
                  invalidMessage={validationErrors.estado}
                  onFocus={() => populateEstados()}
                  onChange={handleOnChangeEstado}
                />
              </Col>
              <Col xs={6}>
                <Label>Oriente da Loja</Label>
                <Select
                  placeholder="Oriente da Loja"
                  value={cidade}
                  options={cidades}
                  loading={loadingCidades}
                  invalidMessage={validationErrors.cidade}
                  noOptionsMessage={() => (estado ? 'Nenhum valor encontrado' : 'Selecione um estado')}
                  onChange={setCidade}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="logradouro">logradouro</Label>
                  <InputGroup
                    className={`input-group-merge ${
                      validationErrors.logradouro ? 'invalid' : ''
                    }`}
                  >
                    <Input
                      id="logradouro"
                      type="text"
                      placeholder="Logradouro"
                      value={logradouro}
                      invalid={!!validationErrors.logradouro}
                      onChange={({ target: { value } }) => setLogradouro(value)}
                    />
                    <InputGroupText>
                      <i className="fa-solid fa-map-location-dot" />
                    </InputGroupText>
                    <FormFeedback>{validationErrors.logradouro}</FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <DatePickerMaskedInput
                  title="Início Mandato"
                  label="Início Mandato"
                  selected={inicio_mandato}
                  invalidMessage={validationErrors.inicio_mandato}
                  onChange={(date) => setInicio_mandato(date)}
                />
              </Col>
              <Col>
                <DatePickerMaskedInput
                  title="Término Mandato"
                  label="Término Mandato"
                  selected={termino_mandato}
                  invalidMessage={validationErrors.termino_mandato}
                  onChange={(date) => setTermino_mandato(date)}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="d-flex justify-content-between w-100">
              <div>
                {id ? (
                  <>
                    <Button
                      color="success"
                      className="mr-2 mt-2"
                      onClick={handleCreateLicenciado}
                    >
                      Licenciar
                    </Button>
                    <Button
                      color="warning"
                      className="mr-2 mt-2"
                      onClick={handleCreateJustificativa}
                    >
                      Justificar
                    </Button>
                    <Button
                      color="info"
                      className="mr-2 mt-2"
                      onClick={handleCreatePresenca}
                    >
                      Registrar Presença
                    </Button>
                    <Button
                      color="danger"
                      className="mr-2 mt-2"
                      onClick={handleCreateRenuncia}
                    >
                      Renunciar
                    </Button>
                    <Button
                      color="danger"
                      className="mt-2 mr-2"
                      onClick={handleCreatePerdeuCargo}
                    >
                      Perdeu o cargo
                    </Button>
                    <Button
                      color="info"
                      className="mt-2"
                      onClick={handleReport}
                    >
                      Relatório
                    </Button>
                  </>
                ) : null}
              </div>
              <Button onClick={handleSave}>Salvar</Button>
            </div>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={showModalLicenciado}
          toggle={() => setShowModalLicenciado(!showModalLicenciado)}
          centered
        >
          <ModalHeader>
            <strong>Licenciar Usuário</strong>
            <Button
              className="close position-absolute top-4 right-4 py-1 px-2"
              onClick={() => setShowModalLicenciado(false)}
            >
              <i className="fas fa-times" />
            </Button>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={12} sm={6}>
                <DatePickerMaskedInput
                  title="Data Início"
                  label="Data Início"
                  selected={licenciado_data_inicio}
                  invalidMessage={validationErrors.licenciado_data_inicio}
                  onChange={(date) => setLicenciado_data_inicio(date)}
                />
              </Col>
              <Col xs={12} sm={6}>
                <DatePickerMaskedInput
                  title="Data Término"
                  label="Data Término"
                  selected={licenciado_data_termino}
                  invalidMessage={validationErrors.licenciado_data_termino}
                  onChange={(date) => setLicenciado_data_termino(date)}
                />
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <Label for="licenciado_observacoes">Observações</Label>
                  <InputGroup
                    className={`input-group-merge ${
                      validationErrors.licenciado_observacoes ? 'invalid' : ''
                    }`}
                  >
                    <Input
                      id="licenciado_observacoes"
                      type="textarea"
                      placeholder="Observações"
                      value={licenciado_observacoes}
                      invalid={!!validationErrors.licenciado_observacoes}
                      onChange={({ target: { value } }) => setLicenciado_observacoes(value)}
                    />
                    <InputGroupText>
                      <i className="fa-solid fa-align-justify" />
                    </InputGroupText>
                    <FormFeedback>
                      {validationErrors.licenciado_observacoes}
                    </FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleSaveLicenciado}>Salvar</Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={showModalJustificativa}
          toggle={() => setShowModalJustificativa(!showModalJustificativa)}
          centered
        >
          <ModalHeader>
            <strong>Justificar Usuário</strong>
            <Button
              className="close position-absolute top-4 right-4 py-1 px-2"
              onClick={() => setShowModalJustificativa(false)}
            >
              <i className="fas fa-times" />
            </Button>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={12}>
                <Select
                  placeholder="Sessão"
                  value={sessao}
                  options={sessoes}
                  invalidMessage={validationErrors.sessao}
                  onChange={setSessao}
                />
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <Label for="justificativa_observacoes">Observações</Label>
                  <InputGroup
                    className={`input-group-merge ${
                      validationErrors.justificativa_observacoes
                        ? 'invalid'
                        : ''
                    }`}
                  >
                    <Input
                      id="justificativa_observacoes"
                      type="textarea"
                      placeholder="Observações"
                      value={justificativa_observacoes}
                      invalid={!!validationErrors.justificativa_observacoes}
                      onChange={({ target: { value } }) => setJustificativa_observacoes(value)}
                    />
                    <InputGroupText>
                      <i className="fa-solid fa-align-justify" />
                    </InputGroupText>
                    <FormFeedback>
                      {validationErrors.justificativa_observacoes}
                    </FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleSaveJustificativa}>Salvar</Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={showModalPresenca}
          toggle={() => setShowModalPresenca(!showModalPresenca)}
          centered
        >
          <ModalHeader>
            <strong>Registrar Presença do Usuário</strong>
            <Button
              className="close position-absolute top-4 right-4 py-1 px-2"
              onClick={() => setShowModalPresenca(false)}
            >
              <i className="fas fa-times" />
            </Button>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={12}>
                <Select
                  placeholder="Sessão"
                  value={sessao}
                  options={sessoes}
                  invalidMessage={validationErrors.sessao}
                  onChange={setSessao}
                />
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <Label for="presenca_observacoes">Observações</Label>
                  <InputGroup
                    className={`input-group-merge ${
                      validationErrors.presenca_observacoes ? 'invalid' : ''
                    }`}
                  >
                    <Input
                      id="presenca_observacoes"
                      type="textarea"
                      placeholder="Observações"
                      value={presenca_observacoes}
                      invalid={!!validationErrors.presenca_observacoes}
                      onChange={({ target: { value } }) => setPresenca_observacoes(value)}
                    />
                    <InputGroupText>
                      <i className="fa-solid fa-align-justify" />
                    </InputGroupText>
                    <FormFeedback>
                      {validationErrors.presenca_observacoes}
                    </FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleSavePresenca}>Salvar</Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={showModalRenuncia}
          toggle={() => setShowModalRenuncia(!showModalRenuncia)}
          centered
        >
          <ModalHeader>
            <strong>Renunciar Usuário</strong>
            <Button
              className="close position-absolute top-4 right-4 py-1 px-2"
              onClick={() => setShowModalRenuncia(false)}
            >
              <i className="fas fa-times" />
            </Button>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={12}>
                <DatePickerMaskedInput
                  title="Data da Renúncia"
                  label="Data da Renúncia"
                  selected={renuncia_data}
                  invalidMessage={validationErrors.renuncia_data}
                  onChange={(date) => setRenuncia_data(date)}
                />
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <Label for="renuncia_observacao">Observações</Label>
                  <InputGroup
                    className={`input-group-merge ${
                      validationErrors.renuncia_observacao ? 'invalid' : ''
                    }`}
                  >
                    <Input
                      id="renuncia_observacao"
                      type="textarea"
                      placeholder="Observações"
                      value={renuncia_observacao}
                      invalid={!!validationErrors.renuncia_observacao}
                      onChange={({ target: { value } }) => setRenuncia_observacao(value)}
                    />
                    <InputGroupText>
                      <i className="fa-solid fa-align-justify" />
                    </InputGroupText>
                    <FormFeedback>
                      {validationErrors.renuncia_observacao}
                    </FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleSaveRenuncia}>Salvar</Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={showModalPc}
          toggle={() => setShowModalPc(!showModalPc)}
          centered
        >
          <ModalHeader>
            <strong>Perdeu o Cargo</strong>
            <Button
              className="close position-absolute top-4 right-4 py-1 px-2"
              onClick={() => setShowModalPc(false)}
            >
              <i className="fas fa-times" />
            </Button>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={12}>
                <DatePickerMaskedInput
                  title="Data"
                  label="Data"
                  selected={pc_data}
                  invalidMessage={validationErrors.pc_data}
                  onChange={(date) => setPc_data(date)}
                />
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <Label for="pc_observacao">Observações</Label>
                  <InputGroup
                    className={`input-group-merge ${
                      validationErrors.pc_observacao ? 'invalid' : ''
                    }`}
                  >
                    <Input
                      id="pc_observacao"
                      type="textarea"
                      placeholder="Observações"
                      value={pc_observacao}
                      invalid={!!validationErrors.pc_observacao}
                      onChange={({ target: { value } }) => setPc_observacao(value)}
                    />
                    <InputGroupText>
                      <i className="fa-solid fa-align-justify" />
                    </InputGroupText>
                    <FormFeedback>
                      {validationErrors.pc_observacao}
                    </FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleSavePc}>Salvar</Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={showModalReport}
          toggle={() => setShowModalReport(!showModalReport)}
          size="xl"
          centered
        >
          <ModalHeader>
            <strong>Gerar Relatório</strong>
            <Button
              className="close position-absolute top-4 right-4 py-1 px-2"
              onClick={() => setShowModalReport(false)}
            >
              <i className="fas fa-times" />
            </Button>
          </ModalHeader>
          <ModalBody>
            <Row className="mt-3">
              <Col>
                <span style={{ fontSize: '0.75rem' }}>
                  {presencaSessoesDescricaoLog}
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <Progress
                  color={
                    presencaSessoesProgress < 100
                      ? 'primary'
                      : 'success'
                  }
                  value={presencaSessoesProgress}
                >
                  {`${presencaSessoesProgress} %`}
                </Progress>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4}>
                <Row>
                  <Col>
                    <Button
                      color="primary"
                      className="mt-2"
                      onClick={handlePresencaSessoesRelatorio}
                      block
                    >
                      Gerar Relatório
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col tag="div" className="d-flex justify-content-center">
                    {presencaSessoesFileCRVUrl ? (
                      <Button className="mt-2 mr-2" tag="a" color="success" disabled>
                        <i className="fa-solid fa-download mr-2" />
                        PDF
                      </Button>
                    ) : null}
                    {presencaSessoesFileCRVUrl ? (
                      <a
                        className="mt-2 ml-2"
                        href={presencaSessoesFileCRVUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button color="success">
                          <i className="fa-solid fa-download mr-2" />
                          CSV
                        </Button>
                      </a>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody tag="div" style={{ height: '100vh', overflowY: 'auto' }}>
                    {results.map((result, index) => renderResults(index, Object.keys(result), results))}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setShowModalReport(false)}>Fechar</Button>
          </ModalFooter>
        </Modal>
      </Container>
    </LayoutAdmin>
  );
}

export default Users;
