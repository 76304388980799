import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupText,
} from 'reactstrap';

import deputadoTie from '../../assets/img/deputado-tie.png';
import GoToTop from '../../components/GoToTop';
import Pagination from '../../components/Pagination';
import { AppContext } from '../../contexts/app';
import LayoutAdmin from '../../layouts/admin';
import api from '../../services/api';

export default function CartaoVisita() {
  const { setLoading } = useContext(AppContext);
  const [deputados, setDeputados] = useState([]);
  const [name, setName] = useState('');
  const [cim, setCim] = useState('');
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (name || cim) {
      setPage(1);
    }
  }, [name, cim]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await api.get(
          `/users?page=${page}&size=10&search=[{"field":"acesso_listadeputados","value":"true"},{"field":"name","value":"${name}"},{"field":"cim","value":"${cim}"}]`,
        );
        setDeputados(data.docs.length ? data.docs : []);
        console.log(data.docs);
        setHasPrevPage(data.hasPrevPage);
        setHasNextPage(data.hasNextPage);
        setTotalPages(data.totalPages);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();

    return () => setDeputados([]);
  }, [page, name, cim]);

  return (
    <LayoutAdmin>
      <Container>
        <GoToTop />
        <Row>
          <Col>
            <Card className="mt-5">
              <CardBody>
                <h3>Lista de contato dos Deputados</h3>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <FormGroup>
              <Label for="name">Busca Por Nome</Label>
              <InputGroup className="input-group-merge">
                <Input
                  id="name"
                  type="text"
                  placeholder="Busca por Nome"
                  value={name}
                  onChange={({ target: { value } }) => setName(value)}
                />
                <InputGroupText>
                  <i className="fas fa-file-signature" />
                </InputGroupText>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col xs={12} sm={6}>
            <FormGroup>
              <Label for="cim">Busca por CIM</Label>
              <InputGroup
                className="input-group-merge"
              >
                <Input
                  id="cim"
                  type="text"
                  placeholder="Busca por CIM"
                  value={cim}
                  onChange={({ target: { value } }) => setCim(value)}
                />
                <InputGroupText>
                  <i className="fa-solid fa-address-card" />
                </InputGroupText>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="mt-3">
              <CardBody>
                <Row>
                  {deputados.map((d) => (
                    <Col key={d.id} xs={12} sm={6}>
                      <Card
                        className="mb-4 shadow"
                        style={{
                          height: '250px',
                        }}
                      >
                        <CardBody>
                          <Row>
                            <Col xs={4}>
                              {d.image ? (
                                <div
                                  style={{
                                    width: '100px',
                                    height: '100px',
                                    backgroundSize: 'cover',
                                    backgroundImage: `url(${d.image_url})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: '50% %',
                                    borderRadius: '50px',
                                  }}
                                />
                              ) : (
                                <div
                                  style={{
                                    width: '100px',
                                    height: '100px',
                                    backgroundSize: 'cover',
                                    backgroundImage: `url(${deputadoTie})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: '50% 50%',
                                    borderRadius: '50px',
                                    border: '1px dashed #c1c1c1',
                                  }}
                                />
                              )}
                            </Col>
                            <Col xs={8}>
                              <div className="w-100 d-flex flex-column align-items-center mb-3">
                                <h5 className="text-primary text-center">
                                  {d.name}
                                </h5>
                                <strong>
                                  CIM: &ensp;
                                  {d.cim}
                                </strong>
                              </div>
                              <div className="w-100 d-flex flex-column align-items-end">
                                {d.telefone_1 ? (
                                  <div className="mb-2">
                                    <i className="fa-brands fa-whatsapp text-success mr-2" />
                                    <a
                                      className="text-success text-underline"
                                      title="Whatsapp"
                                      href={`https://api.whatsapp.com/send?phone=55${d.telefone_1.replace(
                                        /[^\d]+/g,
                                        '',
                                      )}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {d.telefone_1}
                                    </a>
                                  </div>
                                ) : null}
                                {d.email ? (
                                  <div className="mb-2">
                                    <i className="fa-solid fa-envelope text-info mr-2" />
                                    <a
                                      className="text-info text-underline"
                                      title="Whatsapp"
                                      href={`mailto:${d.email}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {d.email}
                                    </a>
                                  </div>
                                ) : null}
                                {d.cidade && d.uf_loja ? (
                                  <div className="mb-2">
                                    <i className="fa-solid fa-map-location-dot mr-2" />
                                    <span>
                                      {d.cidade}
                                      {' - '}
                                      {d.uf_loja}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex justify-content-center">
              <Pagination
                page={page}
                hasNextPage={hasNextPage}
                hasPrevPage={hasPrevPage}
                totalPages={totalPages}
                onPageChange={setPage}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </LayoutAdmin>
  );
}
