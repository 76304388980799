import React, { useState, useCallback, useContext } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Row } from 'reactstrap';

import DatePickerMaskedInput from '../../../components/DatePickerMaskedInput';
import { AppContext } from '../../../contexts/app';
import { useAuth } from '../../../hooks/auth';

import './styles.css';

function Header() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { signOutAdmin, loggedUser } = useAuth();
  const {
    toggleSidebar,
    setLoading,
    search,
    setSearch,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  } = useContext(AppContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleLogout = useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        navigate('/');
        await signOutAdmin();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  const toggleDropdown = useCallback(() => {
    setDropdownOpen((prevState) => !prevState);
  }, []);

  return (
    <div id="header" className="header align-items-start align-items-lg-center">
      <div className="d-flex align-items-start align-items-md-center w-100">
        <Button
          className="d-md-none mr-3"
          color="link"
          size="sm"
          onClick={toggleSidebar}
        >
          <i className="fas fa-bars text-white" style={{ fontSize: '2rem' }} />
        </Button>
        {location.pathname === `/admin/exibicao/${params?.exibicao}/menu/${params?.menu}/submenus/${params?.id}/paginas` ? (
          <Row className="w-100">
            <Col xs={12} lg={4}>
              <Input
                className="mb-2 mb-lg-0"
                type="text"
                placeholder="Pesquisar"
                value={search}
                onChange={({ target: { value } }) => setSearch(value)}
              />
            </Col>
            <Col xs={12} lg={4}>
              <DatePickerMaskedInput
                className="mb-2 mb-lg-0"
                placeholderText="Data de"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                withFormGroup={false}
              />
            </Col>
            <Col xs={12} lg={4}>
              <DatePickerMaskedInput
                className="mb-2 mb-lg-0"
                placeholderText="Data até"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                withFormGroup={false}
              />
            </Col>
          </Row>
        ) : null}
      </div>

      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle color="link" className="text-white">
          {loggedUser?.image ? (
            <div
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '25px',
                backgroundSize: 'cover',
                backgroundImage: `url(${loggedUser?.image_url})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            />
          ) : (
            <i className="fa-solid fa-user-tie" />
          )}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={handleLogout}>Sair</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default Header;
