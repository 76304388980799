/* eslint-disable max-len */
import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import {
  Card,
  Container,
  Row,
  Col,
  CardHeader,
  CardBody,
  Button,
  Progress,
  FormGroup,
  Input,
  Label,
  Table,
} from 'reactstrap';

import { isAfter, isBefore } from 'date-fns';

import DatePickerMaskedInput from '../../components/DatePickerMaskedInput';
import GoToTop from '../../components/GoToTop';
import Notification from '../../components/Notification';
import Select from '../../components/Select';
import { AppContext } from '../../contexts/app';
import LayoutAdmin from '../../layouts/admin';
import api from '../../services/api';

export default function RelatorioPresenca() {
  const notificationRef = useRef();
  const [periodoDe, setPeriodoDe] = useState('');
  const [periodoAte, setPeriodoAte] = useState('');
  const [sessao, setSessao] = useState('');
  const [ativo, setAtivo] = useState(-1);
  const [tipos, setTipos] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([{
    label: 'TODOS',
    value: null,
  }]);
  const [selectedSituacoes, setSelectedSituacoes] = useState([{
    label: 'TODOS',
    value: null,
  }]);
  const [users, setUsers] = useState([]);
  const [results, setResults] = useState([]);
  const [sessoesFilter, setSessoesFilter] = useState([{
    label: 'TODAS',
    value: null,
  }]);
  const [presencaTotal, setPresencaTotal] = useState('');
  const [presencaFileCRVUrl, setPresencaFileCRVUrl] = useState('');
  const [presencaFilePDFUrl, setPresencaFilePDFUrl] = useState('');
  const [presencaSessoesFileCRVUrl, setPresencaSessoesFileCRVUrl] = useState('');
  const [presencaSessoesDescricaoLog, setPresencaSessoesDescricaoLog] = useState('');
  const [presencaSessoesProgress, setPresencaSessoesProgress] = useState(0);
  const [sessoes, setSessoes] = useState([]);
  const [listSessoes, setListSessoes] = useState([]);
  const { setLoading } = useContext(AppContext);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data = [] } = await api.get('/sessoes');
        console.log('🚀 ~ data:', data);
        setSessoes(
          data
            .filter((d) => !d.is_teste)
            .map((d) => ({
              ...d,
              data: d.data,
              value: d.id,
              label: `${d.id} - ${d.nome}`,
            })),
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (sessoes.length && !periodoDe && !periodoAte) {
      setListSessoes([
        ...sessoes.map((d) => ({
          label: d.label,
          value: d.value,
        })),
      ]);
    }
  }, [sessoes, sessoesFilter]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await api.get(
          '/users?size=0',
        );
        setUsers(data.map((d) => ({
          label: `${d.name} - ${d.email} - ${d.cim} - ${d.uf ? d.uf : 'S/UF'}`,
          value: d.id,
        })));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();

    return () => setUsers([]);
  }, []);

  const handleSelectedUser = useCallback((newSelectedUsers) => {
    setSelectedUsers(() => (!newSelectedUsers?.length
      ? [{
        label: 'TODOS',
        value: null,
      }]
      : newSelectedUsers.filter((u) => u.value)));
  }, []);

  const handleSelectedSituacoes = useCallback((newSelectedSituacoes) => {
    setSelectedSituacoes(() => (!newSelectedSituacoes?.length
      ? [{
        label: 'TODOS',
        value: null,
      }]
      : newSelectedSituacoes.filter((u) => u.value)));
  }, []);

  const handleSelectedSession = useCallback((newSelectedSessions) => {
    setSessoesFilter(() => (!newSelectedSessions?.length && (!periodoDe || !periodoAte)
      ? [{
        label: 'TODAS',
        value: null,
      }]
      : newSelectedSessions.filter((s) => s.value)));
  }, [periodoDe, periodoAte]);

  const handleSelectedStarPeriodAndEndPeriodo = useCallback(() => {
    setSessoesFilter([]);
    if (periodoDe && periodoAte) {
      console.log('🚀 ~ handleSelectedStarPeriodAndEndPeriodo ~ periodoDe && periodoAte:', periodoDe, periodoAte);
      const _sessoes = sessoes.slice(0, sessoes.length - 1).filter((s) => isAfter(new Date(s.data), periodoDe) && isBefore(new Date(s.data), periodoAte));
      setListSessoes(_sessoes.filter((s) => !(s.is_presencial && tipos.includes('presencial')) && !(!s.is_presencial && tipos.includes('nao-presencial')) && !(s.is_ordinaria && tipos.includes('ordinaria')) && !(s.is_posse && tipos.includes('posse'))));
      setSessoesFilter(_sessoes.filter((s) => (s.is_presencial && tipos.includes('presencial')) || (!s.is_presencial && tipos.includes('nao-presencial')) || (s.is_ordinaria && tipos.includes('ordinaria')) || (s.is_posse && tipos.includes('posse'))));
      return;
    }
    if (periodoDe) {
      console.log('🚀 ~ handleSelectedStarPeriodAndEndPeriodo ~ periodoDe:', periodoDe);
      const _sessoes = sessoes.slice(0, sessoes.length - 1).filter((s) => isAfter(new Date(s.data), periodoDe));
      setListSessoes(_sessoes.filter((s) => !(s.is_presencial && tipos.includes('presencial')) && !(!s.is_presencial && tipos.includes('nao-presencial')) && !(s.is_ordinaria && tipos.includes('ordinaria')) && !(s.is_posse && tipos.includes('posse'))));
      setSessoesFilter(_sessoes.filter((s) => (s.is_presencial && tipos.includes('presencial')) || (!s.is_presencial && tipos.includes('nao-presencial')) || (s.is_ordinaria && tipos.includes('ordinaria')) || (s.is_posse && tipos.includes('posse'))));
    }

    if (periodoAte) {
      console.log('🚀 ~ handleSelectedStarPeriodAndEndPeriodo ~ periodoAte:', periodoAte);
      const _sessoes = sessoes.slice(0, sessoes.length - 1).filter((s) => isBefore(new Date(s.data), periodoAte));
      setListSessoes(_sessoes.filter((s) => !(s.is_presencial && tipos.includes('presencial')) && !(!s.is_presencial && tipos.includes('nao-presencial')) && !(s.is_ordinaria && tipos.includes('ordinaria')) && !(s.is_posse && tipos.includes('posse'))));
      setSessoesFilter(_sessoes.filter((s) => (s.is_presencial && tipos.includes('presencial')) || (!s.is_presencial && tipos.includes('nao-presencial')) || (s.is_ordinaria && tipos.includes('ordinaria')) || (s.is_posse && tipos.includes('posse'))));
    }

    if (!periodoDe && !periodoAte) {
      const _sessoes = sessoes.filter((s) => (s.is_presencial && tipos.includes('presencial')) || (!s.is_presencial && tipos.includes('nao-presencial')) || (s.is_ordinaria && tipos.includes('ordinaria')) || (s.is_posse && tipos.includes('posse')));
      setListSessoes(sessoes.filter((s) => !(s.is_presencial && tipos.includes('presencial')) && !(!s.is_presencial && tipos.includes('nao-presencial')) && !(s.is_ordinaria && tipos.includes('ordinaria')) && !(s.is_posse && tipos.includes('posse'))));
      setSessoesFilter(_sessoes.length ? _sessoes : [{
        label: 'TODAS',
        value: null,
      }]);
    }
  }, [sessoes, periodoDe, periodoAte, tipos.length]);

  useEffect(() => {
    handleSelectedStarPeriodAndEndPeriodo();
  }, [tipos.length]);

  const handlePresencaTotalizar = useCallback((cb) => {
    (async () => {
      try {
        setPresencaTotal('');
        if (sessao && sessao.value) {
          setLoading(true);
          const { data } = await api.get(`/relatorioPresencaTotal/${sessao.value}`);
          setPresencaTotal(data.total);
          setLoading(false);

          if (typeof cb === 'function') {
            cb(data);
          }
        }
      } catch (error) {
        setPresencaTotal('');
        setLoading(false);
        console.log(error);
      }
    })();
  }, [sessao?.value]);

  const handlePresencaRelatorio = useCallback((cb) => {
    (async () => {
      try {
        if (sessao && sessao.value) {
          setLoading(true);
          const { data } = await api.get(`/relatorioPresencaBySessao/${sessao.value}`);
          setPresencaFileCRVUrl(data.file_csv_url);
          setPresencaFilePDFUrl(data.file_pdf_url);
          setLoading(false);

          if (typeof cb === 'function') {
            cb(data);
          }
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, [sessao?.value]);

  const handlePresencaTotalizarAndRelatorio = useCallback(() => {
    handlePresencaTotalizar(() => {
      handlePresencaRelatorio();
    });
  }, [handlePresencaTotalizar, handlePresencaRelatorio]);

  const handlePresencaSessoesRelatorio = useCallback(() => {
    if (!sessoesFilter?.length) {
      notificationRef.current.notify({
        message: 'Selecione ao menos uma sessão',
        color: 'danger',
      });
      return;
    }

    (async () => {
      try {
        const responseLog = await api.post('/relatorioLogs', {
          descricao: 'Iniciando relatório...',
          finalizado: 0,
        });
        setPresencaSessoesDescricaoLog('Iniciando relatório...');
        setPresencaSessoesProgress(0);
        setResults([]);
        const logId = responseLog.data[0];
        const interval = setInterval(() => {
          api.get(`/relatoriologs/${logId}`)
            .then((response) => {
              const { data } = response;
              setPresencaSessoesDescricaoLog(data.descricao);
              setPresencaSessoesProgress(data.progress);
              if (data.finalizado) {
                console.log('🚀 ~ .then ~ data:', data);
                clearInterval(interval);
                setResults(data.results);
                setPresencaSessoesProgress(100);
                setPresencaSessoesFileCRVUrl(data.file_url);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }, 1000);

        const { data } = await api.get(`/relatorioPresencaBySessoes/${logId}?ids=${JSON.stringify(sessoesFilter.map(({ value }) => value))}&ativo=${ativo}&usersIds=${JSON.stringify(selectedUsers.filter(({ value }) => value).map(({ value }) => value))}&situacoes=${JSON.stringify(selectedSituacoes.filter(({ value }) => value).map(({ value }) => value))}`);
        setPresencaSessoesFileCRVUrl(data.file_csv_url);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [sessoesFilter, ativo, selectedUsers, selectedSituacoes]);

  const renderResults = useCallback((index, keys = [], datas = []) => (
    <Table className="mb-5" bordered size="sm">
      <thead>
        <tr>
          <th style={{ backgroundColor: '#c1c1c1' }} width="300px">{keys[0]}</th>
          <th style={{ backgroundColor: '#f1f1f1', textAlign: 'right' }}>{datas[index][keys[0]]}</th>
        </tr>
      </thead>
      <tbody>
        {keys.slice(1, keys.length - 1).map((key) => (
          <tr>
            <td style={{ backgroundColor: '#c1c1c1' }} width="300px">{key}</td>
            <td style={{ textAlign: 'right' }}>{datas[index][key]}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  ));

  return (
    <LayoutAdmin>
      <Notification ref={notificationRef} />
      <Container>
        <GoToTop />
        <Row>
          <Col>
            <Card className="mt-5">
              <CardHeader>
                <h2>Relatório de Presença</h2>
              </CardHeader>
            </Card>
            <Row className="mt-3">
              <Col>
                <Card>
                  <CardHeader>Sessão</CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        <Select
                          placeholder="Selecione uma Sessão"
                          value={sessao}
                          options={sessoes}
                          onChange={setSessao}
                        />
                      </Col>
                    </Row>
                    {sessao && sessao.value ? (
                      <Row>
                        <Col xs={8}>
                          {presencaTotal ? (
                            <Button
                              color="primary"
                              onClick={handlePresencaTotalizar}
                              style={{
                                height: '75px',
                                width: '100%',
                                maxWidth: '450px',
                                fontSize: '2rem',
                                fontWeight: 'bold',
                                borderRadius: '0.75rem',
                              }}
                            >
                              {presencaTotal}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              onClick={handlePresencaTotalizar}
                              style={{
                                height: '75px',
                                width: '100%',
                                maxWidth: '450px',
                                fontSize: '2rem',
                                fontWeight: 'bold',
                                borderRadius: '0.75rem',
                              }}
                            >
                              À calcular...
                            </Button>
                          )}
                        </Col>
                        <Col xs={4}>
                          <Row>
                            <Col>
                              <Button
                                color="primary"
                                onClick={handlePresencaTotalizarAndRelatorio}
                                style={{
                                  height: '75px',
                                  borderRadius: '0.75rem',
                                }}
                                block
                              >
                                Totalizar
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <Col tag="div" className="d-flex justify-content-center">
                              {presencaFilePDFUrl ? (
                                <a
                                  className="mt-2 mr-2"
                                  href={presencaFilePDFUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Button color="success" block>
                                    <i className="fa-solid fa-download mr-2" />
                                    PDF
                                  </Button>
                                </a>
                              ) : null}
                              {presencaFileCRVUrl ? (
                                <a
                                  className="mt-2 ml-2"
                                  href={presencaFileCRVUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Button color="success" block>
                                    <i className="fa-solid fa-download mr-2" />
                                    CSV
                                  </Button>
                                </a>
                              ) : null}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    Relatório Detalhado
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col xs={12} sm={8}>
                        <Row>
                          <Col xs={2}>
                            <strong>Período:</strong>
                          </Col>
                          <Col xs={10} tag="div" className="d-flex">
                            <DatePickerMaskedInput
                              placeholderText="dd/mm/aaaa"
                              selected={periodoDe}
                              open={false}
                              startOpen={false}
                              isClearable={false}
                              onChange={(date) => setPeriodoDe(date)}
                              onBlurInput={handleSelectedStarPeriodAndEndPeriodo}
                            />
                            <span className="ml-3 mr-3 mt-2">a</span>
                            <DatePickerMaskedInput
                              placeholderText="dd/mm/aaaa"
                              selected={periodoAte}
                              open={false}
                              startOpen={false}
                              isClearable={false}
                              onChange={(date) => setPeriodoAte(date)}
                              onBlurInput={handleSelectedStarPeriodAndEndPeriodo}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={2}>
                            <strong>&nbsp;</strong>
                          </Col>
                          <Col xs={10} tag="div" className="d-flex">
                            <FormGroup check className="mr-3">
                              <Input
                                type="checkbox"
                                checked={tipos.includes('presencial')}
                                onChange={() => (tipos.includes('presencial') ? setTipos((prevStates = []) => prevStates.filter((t) => t !== 'presencial')) : setTipos((prevStates = []) => [...prevStates, 'presencial']))}
                              />
                              <Label check>Presencial</Label>
                            </FormGroup>
                            <FormGroup check className="mr-3">
                              <Input
                                type="checkbox"
                                checked={tipos.includes('nao-presencial')}
                                onChange={() => (tipos.includes('nao-presencial') ? setTipos((prevStates = []) => prevStates.filter((t) => t !== 'nao-presencial')) : setTipos((prevStates = []) => [...prevStates, 'nao-presencial']))}
                              />
                              <Label check>Híbrida</Label>
                            </FormGroup>
                            <FormGroup check className="mr-3">
                              <Input
                                type="checkbox"
                                checked={tipos.includes('ordinaria')}
                                onChange={() => (tipos.includes('ordinaria') ? setTipos((prevStates = []) => prevStates.filter((t) => t !== 'ordinaria')) : setTipos((prevStates = []) => [...prevStates, 'ordinaria']))}
                              />
                              <Label check>Ordinária</Label>
                            </FormGroup>
                            <FormGroup check className="mr-3">
                              <Input
                                type="checkbox"
                                checked={tipos.includes('posse')}
                                onChange={() => (tipos.includes('posse') ? setTipos((prevStates = []) => prevStates.filter((t) => t !== 'posse')) : setTipos((prevStates = []) => [...prevStates, 'posse']))}
                              />
                              <Label check>Posse</Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={2}>
                            <strong>Sessão:</strong>
                          </Col>
                          <Col xs={10}>
                            <Select
                              placeholder="Escolha uma ou mais sessões"
                              value={sessoesFilter}
                              options={listSessoes}
                              isMulti
                              isClearable={false}
                              onChange={handleSelectedSession}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={2}>
                            <strong>Deputado:</strong>
                          </Col>
                          <Col xs={10}>
                            <Select
                              placeholder="Escolha uma ou mais deputado"
                              value={selectedUsers}
                              options={users}
                              onChange={handleSelectedUser}
                              isMulti
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={2}>
                            <strong>Status:</strong>
                          </Col>
                          <Col xs={10} tag="div" className="d-flex">
                            <FormGroup check className="mr-3">
                              <Input
                                type="radio"
                                checked={ativo === -1}
                                onChange={() => setAtivo(-1)}
                              />
                              <Label check>Todos</Label>
                            </FormGroup>
                            <FormGroup check className="mr-3">
                              <Input
                                type="radio"
                                checked={ativo === 1}
                                onChange={() => setAtivo(1)}
                              />
                              <Label check>Somente Ativos</Label>
                            </FormGroup>
                            <FormGroup check className="mr-3">
                              <Input
                                type="radio"
                                checked={ativo === 0}
                                onChange={() => setAtivo(0)}
                              />
                              <Label check>Somente Inativos</Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col xs={2}>
                            <strong>Situação:</strong>
                          </Col>
                          <Col xs={10}>
                            <Select
                              placeholder="Escolha uma ou mais situações"
                              value={selectedSituacoes}
                              options={[
                                { value: 'PRESENTE', label: 'PRESENTE' },
                                { value: 'FALTOU', label: 'FALTOU' },
                                { value: 'JUSTIFICADO', label: 'JUSTIFICADO' },
                                { value: 'LICENCIADO', label: 'LICENCIADO' },
                                { value: 'RENUNCIOU', label: 'RENUNCIOU' },
                                { value: 'PERDEUOCARGO', label: 'PERDEU O CARGO' },
                              ]}
                              onChange={handleSelectedSituacoes}
                              isMulti
                            />
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <span style={{ fontSize: '0.75rem' }}>
                              {presencaSessoesDescricaoLog}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Progress
                              color={
                                presencaSessoesProgress < 100
                                  ? 'primary'
                                  : 'success'
                              }
                              value={presencaSessoesProgress}
                            >
                              {`${presencaSessoesProgress} %`}
                            </Progress>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} sm={4}>
                        <Row>
                          <Col>
                            <Button
                              color="primary"
                              onClick={handlePresencaSessoesRelatorio}
                              style={{
                                height: '75px',
                                fontWeight: 'bold',
                                borderRadius: '0.75rem',
                              }}
                              block
                            >
                              Gerar Relatório
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          <Col tag="div" className="d-flex justify-content-center">
                            {presencaSessoesFileCRVUrl ? (
                              <Button className="mt-2 mr-2" tag="a" color="success" disabled>
                                <i className="fa-solid fa-download mr-2" />
                                PDF
                              </Button>
                            ) : null}
                            {presencaSessoesFileCRVUrl ? (
                              <a
                                className="mt-2 ml-2"
                                href={presencaSessoesFileCRVUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Button color="success">
                                  <i className="fa-solid fa-download mr-2" />
                                  CSV
                                </Button>
                              </a>
                            ) : null}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody tag="div" style={{ height: '100vh', overflowY: 'auto' }}>
                    {results.map((result, index) => renderResults(index, Object.keys(result), results)) }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </LayoutAdmin>
  );
}
