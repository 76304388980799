import React, { useEffect, useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Row,
} from 'reactstrap';

function CarouselComponent({ items, initialActiveIndex = 0, maxHeight }) {
  const [activeIndex, setActiveIndex] = useState(initialActiveIndex);
  const [animating, setAnimating] = useState(false);

  useEffect(() => {
    setActiveIndex(initialActiveIndex);
  }, [initialActiveIndex]);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => (
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={item.id}
    >
      {maxHeight ? (
        <img
          src={item.imagem_url || item.file_url || item.url}
          alt={item.imagem_url || item.file_url || item.url}
          width="100%"
          style={{
            maxHeight: '375px',
          }}
        />
      ) : (
        <img
          src={item.imagem_url || item.file_url || item.url}
          alt={item.imagem_url || item.file_url || item.url}
          width="100%"
        />
      )}
    </CarouselItem>
  ));

  return (
    <Row>
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        className="w-100"
      >
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
        <CarouselControl
          direction="prev"
          directionText="."
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="."
          onClickHandler={next}
        />
      </Carousel>
    </Row>
  );
}

export default CarouselComponent;
