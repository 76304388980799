/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import RichTextEditor from 'react-rte';
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Label,
  FormGroup,
  Input,
  FormFeedback,
  Button,
} from 'reactstrap';

import * as Yup from 'yup';

import EmailLogStatus from '../../components/EmailLogStatus';
import GoToTop from '../../components/GoToTop';
import Notification from '../../components/Notification';
import Select from '../../components/Select';
import UploadFiles from '../../components/UploadFiles';
import { AppContext } from '../../contexts/app';
import LayoutAdmin from '../../layouts/admin';
import api from '../../services/api';

export default function EmailSend() {
  const notificationRef = useRef();
  const { setLoading } = useContext(AppContext);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [listUsers, setListUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [titulo, setTitulo] = useState('');
  const [uf, setUf] = useState('');
  const [mensagem, setMensagem] = useState(RichTextEditor.createEmptyValue());
  const [uploadedFile, setUploadedFiles] = useState([]);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [emailId, setEmailId] = useState();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await api.get(
          '/users?size=0&search=[{"field":"has_email","value":"true"}]',
        );
        setUsers(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();

    return () => setUsers([]);
  }, []);

  useEffect(() => {
    if (users.length) {
      if (selectedUsers.length) {
        if (selectedUsers[0].value === 'TODOS') {
          setListUsers([
            {
              label: 'TODOS',
              value: 'TODOS',
            },
          ]);
        } else {
          setListUsers([
            ...users.map((d) => ({
              label: `${d.name} - ${d.email} - ${d.cim} - ${d.uf ? d.uf : 'S/UF'}`,
              value: d.id,
            })),
          ]);
        }
      } else {
        setListUsers([
          {
            label: 'TODOS',
            value: 'TODOS',
          },
          ...users.map((d) => ({
            label: `${d.name} - ${d.email} - ${d.cim} - ${d.uf ? d.uf : 'S/UF'}`,
            value: d.id,
          })),
        ]);
      }
    }
  }, [users, selectedUsers]);

  const handleEndSendEmail = useCallback(() => {
    notificationRef.current.notify({
      message: 'Emails enviados',
    });
    setTimeout(() => {
      setSendingEmail(false);
    }, 5000);
  }, []);

  const handleSubmit = async () => {
    try {
      const data = {
        titulo,
        mensagem: mensagem ? (mensagem?.toString?.('html')?.replace?.('<p><br></p>', '') || titulo) : titulo,
      };

      const schema = Yup.object().shape({
        titulo: Yup.string().required('Informe o título do email'),
        mensagem: Yup.string().required('Informe a mensagem do email'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setLoading(true);
      const ids = selectedUsers && selectedUsers.length
        ? selectedUsers[0].value === 'TODOS'
          ? null
          : selectedUsers.map((user) => user.value)
        : null;
      const attachments = uploadedFile.length
        ? uploadedFile.filter((u) => u.error === false).map((u) => u.filename)
        : [];

      const res = await api.post('/sendEmails', {
        uf,
        ids,
        attachments,
        subject: data.titulo,
        message: data.mensagem,
      });
      console.log('🚀 ~ handleSubmit ~ res:', res);
      setLoading(false);
      setSendingEmail(true);

      setEmailId(res?.data?.emailId);
      setSelectedUsers([]);
      setValidationErrors({});
      setTitulo('');
      setUf('');
      setMensagem(RichTextEditor.createEmptyValue());
      setUploadedFiles([]);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setSendingEmail(false);
      setValidationErrors({});
      // Validation failed
      if (err instanceof Yup.ValidationError) {
        notificationRef.current.notify({
          message: 'Por favor, preencha todos os dados necessários para enviar o email',
          color: 'warning',
        });
        const ve = {};
        err.inner.forEach((e) => {
          ve[e.path] = e.message;
        });
        setValidationErrors(ve);
        return;
      }

      if (err && err.response && err.response.data) {
        const { message } = err.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });

        return;
      }

      notificationRef.current.notify({
        message: 'Algo inesperado aconteceu e não foi possível enviar o email',
        color: 'danger',
      });
    }
  };

  return (
    <LayoutAdmin>
      <Container>
        <Notification ref={notificationRef} />
        <GoToTop />
        <Row>
          <Col>
            <Card className="mt-5">
              <CardBody>
                <h3>Enviar Email</h3>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {sendingEmail ? (
          <Row>
            <Col>
              <EmailLogStatus
                emailId={emailId}
                sendingEmail={sendingEmail}
                onEndSendEmail={handleEndSendEmail}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              <Row>
                <Col>
                  <Card className="mt-3 shadow">
                    <CardBody>
                      <Label>Enviar Email por Estado</Label>
                      <FormGroup>
                        <Input
                          type="select"
                          value={uf}
                          onChange={({ target: { value } }) => setUf(value)}
                        >
                          <option label="Selecione Estado" value="" />
                          <option label="Acre" value="AC" />
                          <option label="Alagoas" value="AL" />
                          <option label="Amapá" value="AP" />
                          <option label="Amazonas" value="AM" />
                          <option label="Bahia" value="BA" />
                          <option label="Ceará" value="CE" />
                          <option label="Distrito Federal" value="DF" />
                          <option label="Espírito Santo" value="ES" />
                          <option label="Goiás" value="GO" />
                          <option label="Maranhão" value="MA" />
                          <option label="Mato Grosso" value="MT" />
                          <option label="Mato Grosso do Sul" value="MS" />
                          <option label="Minas Gerais" value="MG" />
                          <option label="Pará" value="PA" />
                          <option label="Paraíba" value="PB" />
                          <option label="Paraná" value="PR" />
                          <option label="Pernambuco" value="PE" />
                          <option label="Piauí" value="PI" />
                          <option label="Rio de Janeiro" value="RJ" />
                          <option label="Rio Grande do Norte" value="RN" />
                          <option label="Rio Grande do Sul" value="RS" />
                          <option label="Rondônia" value="RO" />
                          <option label="Roraima" value="RR" />
                          <option label="Santa Catarina" value="SC" />
                          <option label="São Paulo" value="SP" />
                          <option label="Sergipe" value="SE" />
                          <option label="Tocantins" value="TO" />
                        </Input>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card className="mt-3 shadow">
                    <CardBody>
                      <Label>E/OU Selecione os deputados</Label>
                      <Select
                        placeholder="E/OU Selecione os deputados"
                        value={selectedUsers}
                        options={listUsers}
                        onChange={setSelectedUsers}
                        isMulti
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card className="mt-1 shadow">
                    <CardBody>
                      <FormGroup>
                        <Label>Título</Label>
                        <Input
                          type="text"
                          placeholder="Título"
                          value={titulo}
                          invalid={!!validationErrors.titulo}
                          onChange={({ target: { value } }) => setTitulo(value)}
                        />
                        <FormFeedback>{validationErrors.titulo}</FormFeedback>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card className="mt-1 shadow">
                    <CardBody>
                      <FormGroup>
                        <Label>Anexos</Label>
                        <UploadFiles
                          accept={{
                            'image/*': [],
                            'video/*': [],
                            'application/pdf': ['.pdf'],
                            'application/vnd.ms-excel': [],
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                                [],
                            'application/docx': [],
                            'text/plain': [],
                            'text/csv': [],
                            'application/msword': [],
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                                [],
                          }}
                          url="/emailFiles"
                          onUploadedFiles={setUploadedFiles}
                        />
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card className="mt-1 shadow">
                    <CardBody>
                      <FormGroup>
                        <Label>Mensagem</Label>
                        <RichTextEditor
                          className="text-dark"
                          value={mensagem}
                          onChange={setMensagem}
                        />
                        <div className="text-danger">{validationErrors.mensagem}</div>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card className="mt-1 shadow">
                    <CardBody>
                      <div className="d-flex justify-content-end">
                        <Button color="success" onClick={handleSubmit}>
                          Enviar Email
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Container>
    </LayoutAdmin>
  );
}
