import Admins from '../pages/Admins';
import CartaoVisita from '../pages/CartaoVisita';
import Diplomacao from '../pages/Diplomacao';
import Email from '../pages/Email';
import EmailLog from '../pages/EmailLog';
import EmailSend from '../pages/EmailSend';
import Home from '../pages/HomeAdmin';
import Pagina from '../pages/Pagina';
import PaginaNew from '../pages/PaginaNew';
import PaginaNewFiles from '../pages/PaginaNewFiles';
import Paginas from '../pages/Paginas';
import PaginaUpdate from '../pages/PaginaUpdate';
import RelatorioPresenca from '../pages/RelatorioPresenca';
import RelatorioVotacao from '../pages/RelatorioVotacao';
import Roles from '../pages/Roles';
import Dispositivos from '../pages/UserDevice';
import Usuarios from '../pages/Users';
import UsuariosCargosPerdidos from '../pages/UsersCargosPerdidos';
import UsuariosJustificados from '../pages/UsersJustificados';
import UsuariosLicenciados from '../pages/UsersLicenciados';
import UsuariosRenunciados from '../pages/UsersRenunciados';

const routes = [
  {
    path: '/admin/',
    Component: Home,
  },
  {
    path: '/admin/funcoes',
    Component: Roles,
  },
  {
    path: '/admin/administradores',
    Component: Admins,
  },
  {
    path: '/admin/usuarios',
    Component: Usuarios,
  },
  {
    path: '/admin/usuarios-licenciados',
    Component: UsuariosLicenciados,
  },
  {
    path: '/admin/usuarios-justificados',
    Component: UsuariosJustificados,
  },
  {
    path: '/admin/usuarios-renunciados',
    Component: UsuariosRenunciados,
  },
  {
    path: '/admin/usuarios-cargos-perdidos',
    Component: UsuariosCargosPerdidos,
  },
  {
    path: '/admin/cartao-visitas',
    Component: CartaoVisita,
  },
  {
    path: '/admin/enviar-emails',
    Component: EmailSend,
  },
  {
    path: '/admin/emails',
    Component: Email,
  },
  {
    path: '/admin/diplomacoes',
    Component: Diplomacao,
  },
  {
    path: '/admin/email-detalhes/:id',
    Component: EmailLog,
  },
  {
    path: '/admin/dispositivos',
    Component: Dispositivos,
  },
  {
    path: '/admin/exibicao/:exibicao/menu/:menu/submenus/:id/paginas',
    Component: Paginas,
  },
  {
    path: '/admin/exibicao/:exibicao/menu/:menu/submenus/:id/pagina',
    Component: Pagina,
  },
  {
    path: '/admin/exibicao/:exibicao/menu/:menu/submenus/:id/cadastro',
    Component: PaginaNew,
  },
  {
    path: '/admin/exibicao/:exibicao/menu/:menu/submenus/:submenu/cadastro/:id',
    Component: PaginaNewFiles,
  },
  {
    path: '/admin/exibicao/:exibicao/menu/:menu/submenus/:submenu/pagina/:id',
    Component: PaginaUpdate,
  },
  {
    path: '/admin/relatorio-presenca',
    Component: RelatorioPresenca,
  },
  {
    path: '/admin/relatorio-votacao',
    Component: RelatorioVotacao,
  },
];

export default routes;
