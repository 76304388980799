const navigation = [
  {
    to: '/admin/',
    name: 'HOME',
    icon: 'fas fa-home',
    exact: true,
  },
  {
    to: '/admin/administradores',
    name: 'Administradores',
    icon: 'fas fa-user-cog',
  },
  {
    to: '/admin/funcoes',
    name: 'Cargos',
    icon: 'fas fa-user-cog',
  },
  {
    to: '/admin/usuarios',
    name: 'Usuários',
    icon: 'fas fa-user',
  },
  {
    to: '/admin/usuarios-licenciados',
    name: 'Licenciados',
    icon: 'fa-solid fa-user-minus',
  },
  {
    to: '/admin/usuarios-justificados',
    name: 'Justificativas',
    icon: 'fa-solid fa-user-pen',
  },
  {
    to: '/admin/usuarios-renunciados',
    name: 'Renúncias',
    icon: 'fa-solid fa-user-xmark',
  },
  {
    to: '/admin/usuarios-cargos-perdidos',
    name: 'Cargos Perdidos',
    icon: 'fa-solid fa-user-xmark',
  },
  {
    to: '/admin/cartao-visitas',
    name: 'Lista de Contatos',
    icon: 'fa-solid fa-id-card',
  },
  {
    to: '/admin/enviar-emails',
    name: 'Enviar Email',
    icon: 'fa-regular fa-paper-plane',
  },
  {
    to: '/admin/emails',
    name: 'Emails',
    icon: 'fa-solid fa-envelope',
  },
  {
    to: '/admin/diplomacoes',
    name: 'Diplomações',
    icon: 'fa-solid fa-address-book',
  },
];

export default navigation;
