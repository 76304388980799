import React, { useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from 'reactstrap';

import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import TableManager from '../../components/TableManager';
import { useManager } from '../../hooks/manager';
import LayoutAdmin from '../../layouts/admin';

function Email() {
  const navigate = useNavigate();
  const { load } = useManager();

  useEffect(() => {
    load('/emails');
  }, [load]);

  const dataFormatter = useCallback((row) => (
    row.created_at ? (
      <span>
        {format(new Date(row.created_at), 'dd/MM/yyyy HH:mm:ss', { locale: ptBR })}
      </span>
    ) : (
      <span />
    )
  ), []);

  const columns = useMemo(() => [
    {
      dataField: 'subject',
      text: 'Título',
      textFilter: true,
    },
    {
      dataField: 'created_at',
      text: 'Data',
      formatter: dataFormatter,
    },
  ], []);

  const actions = useMemo(
    () => [
      {
        name: 'view-email',
        tooltip: 'Visualizar',
        color: 'info',
        className: 'mt-2 mr-2 text-white',
        icon: 'fas fa-eye',
        onClick: (row) => navigate(`/admin/email-detalhes/${row.id}`),
      },
    ],
    [],
  );

  return (
    <LayoutAdmin>
      <Container>
        <Row className="mt-5">
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h3>Emails</h3>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableManager
              columns={columns}
              actions={actions}
            />
          </Col>
        </Row>
      </Container>
    </LayoutAdmin>
  );
}

export default Email;
