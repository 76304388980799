import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Card,
  Container,
  Row,
  Col,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap';

import GoToTop from '../../components/GoToTop';
import Select from '../../components/Select';
import { AppContext } from '../../contexts/app';
import LayoutAdmin from '../../layouts/admin';
import api from '../../services/api';

export default function RelatorioVotacao() {
  const [sessao, setSessao] = useState('');
  const [tema, setTema] = useState('');
  const [votacaoFileCRVUrl, setVotacaoFileCRVUrl] = useState('');
  const [votacaoFilePDFUrl, setVotacaoFilePDFUrl] = useState('');
  const [votacaoTemaFileCRVUrl, setVotacaoTemaFileCRVUrl] = useState('');
  const [sessoes, setSessoes] = useState([]);
  const [temas, setTemas] = useState([]);
  const { setLoading } = useContext(AppContext);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data = [] } = await api.get('/sessoes');
        setSessoes(
          data
            .filter((d) => !d.is_teste)
            .map((d) => ({
              value: d.id,
              label: `${d.id} - ${d.nome}`,
            })),
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (sessao?.value) {
          setLoading(true);
          const { data = [] } = await api.get(`/relatorioVotacaoTemasMultiplaBySessao/${sessao.value}`);
          setTemas(
            data
              .map((d) => ({
                value: d.id,
                label: `${d.id} - ${d.descricao}`,
              })),
          );
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, [sessao?.value]);

  const handleVotacaoRelatorio = useCallback(() => {
    (async () => {
      try {
        if (sessao && sessao.value) {
          setLoading(true);
          const { data } = await api.get(`/relatorioVotacaoBySessao/${sessao.value}`);
          setVotacaoFileCRVUrl(data.file_csv_url);
          setVotacaoFilePDFUrl(data.file_pdf_url);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, [sessao?.value]);

  const handleVotacaoTemaRelatorio = useCallback(() => {
    (async () => {
      try {
        if (tema && tema.value) {
          setLoading(true);
          const { data } = await api.get(`/relatorioVotacaoTemasMultipla/${tema.value}`);
          setVotacaoTemaFileCRVUrl(data.file_csv_url);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, [tema?.value]);

  return (
    <LayoutAdmin>
      <Container>
        <GoToTop />
        <Row>
          <Col>
            <Card className="mt-5">
              <CardHeader>
                <h2>Relatório de Votação</h2>
              </CardHeader>
            </Card>
            <Row className="mt-3">
              <Col>
                <Card>
                  <CardHeader>Sessões</CardHeader>
                  <CardBody>
                    <Select
                      placeholder="Selecione uma Sessão"
                      value={sessao}
                      options={sessoes}
                      onChange={setSessao}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {sessao && sessao.value ? (
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xs={6}>
                          <Button
                            color="primary"
                            onClick={handleVotacaoRelatorio}
                            block
                          >
                            Gerar Relatório
                          </Button>
                        </Col>
                        <Col xs={3}>
                          {votacaoFileCRVUrl ? (
                            <a
                              href={votacaoFileCRVUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button color="success" block>
                                <i className="fa-solid fa-download mr-2" />
                                CSV
                              </Button>
                            </a>
                          ) : null}
                        </Col>
                        <Col xs={3}>
                          {votacaoFilePDFUrl ? (
                            <a
                              href={votacaoFilePDFUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button color="success" block>
                                <i className="fa-solid fa-download mr-2" />
                                PDF
                              </Button>
                            </a>
                          ) : null}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : null}
            {temas.length ? (
              <>
                <Row className="mt-3">
                  <Col>
                    <Card>
                      <CardHeader>Temas</CardHeader>
                      <CardBody>
                        <Select
                          placeholder="Selecione uma Tema"
                          value={tema}
                          options={temas}
                          onChange={setTema}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                {tema && tema.value ? (
                  <Row>
                    <Col>
                      <Card>
                        <CardBody>
                          <Row>
                            <Col xs={6}>
                              <Button
                                color="primary"
                                onClick={handleVotacaoTemaRelatorio}
                                block
                              >
                                Gerar Relatório
                              </Button>
                            </Col>
                            <Col xs={3}>
                              {votacaoTemaFileCRVUrl ? (
                                <a
                                  href={votacaoTemaFileCRVUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Button color="success" block>
                                    <i className="fa-solid fa-download mr-2" />
                                    CSV
                                  </Button>
                                </a>
                              ) : null}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                ) : null}
              </>
            ) : null}
          </Col>
        </Row>
      </Container>
    </LayoutAdmin>
  );
}
