import React from 'react';
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
} from 'reactstrap';

import saflportalimg from '../../assets/img/logocompleto.png';
import GoToTop from '../../components/GoToTop';
import LayoutAdmin from '../../layouts/admin';

export default function Home() {
  return (
    <LayoutAdmin>
      <Container>
        <GoToTop />
        <Row>
          <Col>
            <Card className="mt-5">
              <CardHeader>
                <div className="d-flex justify-content-center">
                  <h2>Área Administrativa do SAFL PORTAL</h2>
                </div>
              </CardHeader>
              <CardBody>
                <Row className="justify-content-center">
                  <img src={saflportalimg} alt="SAFL PORTAL" width="100%" />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </LayoutAdmin>
  );
}
