import React from 'react';

import Header from '../Header';

function Content({ children }) {
  return (
    <div className="w-100 mh-100">
      <Header />
      <div style={{ minHeight: '80%' }}>
        {children}
      </div>
    </div>
  );
}

export default Content;
