export const replaceCharsNoAscii = (str) => {
  // Normalize the string to decompose characters into their base form
  const sanitizedStr = str
    .normalize('NFKD')
    // Remove invalid characters (replacement character "�")
    .replace(/[\uFFFD]/g, '')
    // Recompose characters back to their original form
    .normalize('NFC');

  return sanitizedStr;
};

export const replaceSpecialChars = (str) => {
  const specialCharsMap = {
    À: 'A',
    Á: 'A',
    Â: 'A',
    Ã: 'A',
    Ä: 'A',
    Å: 'A',
    Æ: 'AE',
    à: 'a',
    á: 'a',
    â: 'a',
    ã: 'a',
    ä: 'a',
    å: 'a',
    æ: 'ae',
    È: 'E',
    É: 'E',
    Ê: 'E',
    Ë: 'E',
    è: 'e',
    é: 'e',
    ê: 'e',
    ë: 'e',
    Ì: 'I',
    Í: 'I',
    Î: 'I',
    Ï: 'I',
    ì: 'i',
    í: 'i',
    î: 'i',
    ï: 'i',
    Ò: 'O',
    Ó: 'O',
    Ô: 'O',
    Õ: 'O',
    Ö: 'O',
    ò: 'o',
    ó: 'o',
    ô: 'o',
    õ: 'o',
    ö: 'o',
    Ø: 'O',
    Ù: 'U',
    Ú: 'U',
    Û: 'U',
    Ü: 'U',
    ù: 'u',
    ú: 'u',
    û: 'u',
    ü: 'u',
    ǘ: 'u',
    ǜ: 'u',
    ǖ: 'u',
    ǚ: 'u',
    Ń: 'N',
    Ñ: 'N',
    Ň: 'N',
    Ņ: 'N',
    Ŋ: 'N',
    Ǹ: 'N',
    ń: 'n',
    ñ: 'n',
    ň: 'n',
    ņ: 'n',
    ŋ: 'n',
    ǹ: 'n',
    ḿ: 'm',
    Ç: 'C',
    ç: 'c',
    ß: 'S',
    Ÿ: 'Y',
    ÿ: 'y',
    Œ: 'OE',
    œ: 'oe',
    Ŕ: 'R',
    Ř: 'R',
    Ŗ: 'R',
    ŕ: 'r',
    ř: 'r',
    ŗ: 'r',
    Ś: 'S',
    Š: 'S',
    Ŝ: 'S',
    Ş: 'S',
    ś: 's',
    š: 's',
    ŝ: 's',
    ş: 's',
    Ṕ: 'P',
    Ṗ: 'P',
    ṕ: 'p',
    ṗ: 'p',
    Ẃ: 'W',
    Ŵ: 'W',
    Ẅ: 'W',
    ẃ: 'w',
    ŵ: 'w',
    ẅ: 'w',
    Ǵ: 'G',
    Ĝ: 'G',
    Ğ: 'G',
    Ġ: 'G',
    Ģ: 'G',
    ǵ: 'g',
    ĝ: 'g',
    ğ: 'g',
    ġ: 'g',
    ģ: 'g',
    ẍ: 'x',
    Ź: 'Z',
    Ž: 'Z',
    Ż: 'Z',
    ź: 'z',
    ž: 'z',
    ż: 'z',
    ĥ: 'h',
    ḧ: 'h',
    ẖ: 'h',
    '·': '',
    '´': '',
    '¨': '',
    '¯': '',
    '¸': '',
    '¹': '',
    º: '',
    '»': '',
    '¿': '',
    '&': 'E',
    '¡': '',
    '¢': 'c',
    '£': 'l',
    '¤': 'o',
    '¥': 'y',
    '¦': '',
    '§': '',
    '©': '',
    ª: '',
    '«': '',
    '¬': '',
    '®': '',
    '°': '',
    '±': '',
    '²': '',
    '³': '',
    µ: '',
    '¶': '',
    '¼': '',
    '½': '',
    '¾': '',
    '×': '',
    '÷': '',
    ø: '',
  };

  return str
    // eslint-disable-next-line no-control-regex
    .replace(/[^\x00-\x7F]/g, (char) => specialCharsMap[char] || char)
    .replace(/(\b\w+)'(\w+\b)/g, '$1$2');
};

export const convertStringToAlphaNumeric = (strEntry) => {
  if (!strEntry) return '';
  if (typeof strEntry !== 'string') return strEntry;

  // 1. Normalizar o strEntry
  strEntry = replaceCharsNoAscii(strEntry);

  // 2. Remover caracteres indesejados
  const allowedCharacters = '0-9A-Za-zÀ-ÖØ-öø-ÿ';
  const regexMotAllowedCharacters = new RegExp(`[^${allowedCharacters}\\s]`, 'gu');
  strEntry = strEntry.replace(regexMotAllowedCharacters, ' ');

  const result = strEntry
    .trim()
    .split(' ')
    .filter((word) => word.length > 1)
    .join(' ');

  return result;
};

export const normalizeAndCapitalizeString = (str) => {
  const normalizedString = convertStringToAlphaNumeric(str);
  return `${normalizedString}`
    .trim()
    .toLocaleLowerCase()
    .split(' ')
    .map((splittedName) => `${`${splittedName}`
      .charAt(0)
      .toUpperCase()}${splittedName.slice(1)}`)
    .join(' ');
};
