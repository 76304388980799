/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable max-len */
import React, { useState, useEffect, useCallback, useRef, useMemo, useContext } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

import * as Yup from 'yup';

import Notification from '../../components/Notification';
import TableManager from '../../components/TableManager';
import { AppContext } from '../../contexts/app';
import { normalizeAndCapitalizeString, replaceSpecialChars } from '../../helpers/functions';
import { useManager } from '../../hooks/manager';
import LayoutAdmin from '../../layouts/admin';

function Roles() {
  const notificationRef = useRef();
  const { setLoading } = useContext(AppContext);
  const [validationErrors, setValidationErrors] = useState({});
  const { load, store, update } = useManager();
  const [id, setId] = useState();
  const [name, setName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [description, setDescription] = useState('');
  const [hasAccessForlegis, setHasAccessForlegis] = useState(false);
  const [hasAccessListContacts, setHasAccessListContacts] = useState(false);
  const [hasAccessApp, setHasAccessApp] = useState(false);
  const [hasAccessPortal, setHasAccessPortal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    load('/roles');
  }, [load]);

  const handleCreate = useCallback(() => {
    setValidationErrors({});
    setId('');
    setName('');
    setDisplayName('');
    setDescription('');
    setHasAccessForlegis(false);
    setHasAccessListContacts(false);
    setHasAccessApp(false);
    setHasAccessPortal(false);
    setShowModal(true);
  }, []);

  const handleUpdate = useCallback((data) => {
    setValidationErrors({});
    setId(data.id);
    setName(data?.name ? data.name : '');
    setDisplayName(data?.display_name ? data.display_name : '');
    setDescription(data?.description ? data.description : '');
    setHasAccessForlegis(data?.has_access_forlegis ? data.has_access_forlegis : false);
    setHasAccessListContacts(data?.has_access_list_contact ? data.has_access_list_contact : false);
    setHasAccessApp(data?.has_access_app ? data.has_access_app : false);
    setHasAccessPortal(data?.has_access_portal ? data.has_access_portal : false);
    setShowModal(true);
  }, []);

  const handleSave = useCallback(() => {
    (async () => {
      try {
        const data = {
          name,
          display_name: displayName,
          description,
          has_access_forlegis: hasAccessForlegis,
          has_access_list_contact: hasAccessListContacts,
          has_access_app: hasAccessApp,
          has_access_portal: hasAccessPortal,
        };

        const schema = Yup.object().shape({
          name: Yup.string().required('Informe o nome do Cargo'),
          display_name: Yup.string().required('Informe o nome de exibição do Cargo'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (id) {
          update(id, '/roles', data);
        } else {
          store('/roles', data);
        }
        setShowModal(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message:
              'Por favor, preencha todos os dados necessários para salvar',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notificationRef.current.notify({
            message,
            color: 'danger',
          });

          return;
        }

        notificationRef.current.notify({
          message: 'Algo inesperado aconteceu e não foi possível salvar',
          color: 'danger',
        });
      }
    })();
  }, [name, displayName, description, store, update, id, hasAccessForlegis, hasAccessListContacts, hasAccessApp, hasAccessPortal, setLoading]);

  const columns = useMemo(() => [
    {
      dataField: 'name',
      text: 'Nome',
      textFilter: true,
    },
    {
      dataField: 'display_name',
      text: 'Exibição',
      textFilter: true,
    },
    {
      dataField: 'description',
      text: 'Descrição',
      textFilter: true,
    },
    {
      dataField: 'has_access_forlegis',
      text: 'Forlegis',
      formatter: (row) => (row?.has_access_forlegis ? <i id={`has_access_forlegis-${row?.id}`} className="fa-solid fa-circle-check text-success" /> : <i id={`has_access_forlegis-${row?.id}`} className="fa-solid fa-circle-xmark text-danger" />),
    },
    {
      dataField: 'has_access_list_contact',
      text: 'Lista Deputados',
      formatter: (row) => (row?.has_access_list_contact ? <i id={`has_access_list_contact-${row?.id}`} className="fa-solid fa-circle-check text-success" /> : <i id={`has_access_list_contact-${row?.id}`} className="fa-solid fa-circle-xmark text-danger" />),
    },
    {
      dataField: 'has_access_app',
      text: 'Aplicativo',
      formatter: (row) => (row?.has_access_app ? <i id={`has_access_app-${row?.id}`} className="fa-solid fa-circle-check text-success" /> : <i id={`has_access_app-${row?.id}`} className="fa-solid fa-circle-xmark text-danger" />),
    },
    {
      dataField: 'has_access_portal',
      text: 'Portal',
      formatter: (row) => (row?.has_access_portal ? <i id={`has_access_portal-${row?.id}`} className="fa-solid fa-circle-check text-success" /> : <i id={`has_access_portal-${row?.id}`} className="fa-solid fa-circle-xmark text-danger" />),
    },
  ], []);

  return (
    <LayoutAdmin>
      <Notification ref={notificationRef} />
      <Container>
        <Row className="mt-5">
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h3>Cargo</h3>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col>
                    <Row>
                      <Col xs={12} sm={5} md={3} className="mb-3 mb-xl-0">
                        <Button block color="success" onClick={handleCreate}>
                          Cadastrar
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableManager
              columns={columns}
              onUpdate={(row) => handleUpdate(row)}
            />
          </Col>
        </Row>
        <Modal
          isOpen={showModal}
          toggle={() => setShowModal(!showModal)}
          centered
        >
          <ModalHeader>
            <strong>Cargo</strong>
            <Button
              className="close position-absolute top-4 right-4 py-1 px-2"
              onClick={() => setShowModal(false)}
            >
              <i className="fas fa-times" />
            </Button>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <Label for="name">Nome</Label>
                  <InputGroup
                    className={`input-group-merge ${validationErrors.name ? 'invalid' : ''
                    }`}
                  >
                    <Input
                      id="name"
                      type="text"
                      placeholder="Nome"
                      value={name}
                      invalid={!!validationErrors.name}
                      onChange={({ target: { value } }) => {
                        setName(`${replaceSpecialChars(value)}`.toLocaleUpperCase());
                        setDisplayName(normalizeAndCapitalizeString(value));
                      }}
                    />
                    <InputGroupText>
                      <i className="fas fa-file-signature" />
                    </InputGroupText>
                    <FormFeedback>{validationErrors.name}</FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <Label for="displayName">Nome de Exibição</Label>
                  <InputGroup
                    className={`input-group-merge ${validationErrors.displayName ? 'invalid' : ''
                    }`}
                  >
                    <Input
                      id="displayName"
                      type="text"
                      placeholder="Nome de Exibição"
                      value={displayName}
                      invalid={!!validationErrors.displayName}
                      onChange={({ target: { value } }) => {
                        setDisplayName(normalizeAndCapitalizeString(value));
                      }}
                    />
                    <InputGroupText>
                      <i className="fas fa-file-signature" />
                    </InputGroupText>
                    <FormFeedback>{validationErrors.displayName}</FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <Label for="description">Descrição do Cargo</Label>
                  <InputGroup
                    className={`input-group-merge ${validationErrors.description ? 'invalid' : ''
                    }`}
                  >
                    <Input
                      id="description"
                      type="textarea"
                      placeholder="Descrição"
                      value={description}
                      invalid={!!validationErrors.description}
                      onChange={({ target: { value } }) => {
                        setDescription(value);
                      }}
                    />
                    <InputGroupText>
                      <i className="fas fa-file-signature" />
                    </InputGroupText>
                    <FormFeedback>{validationErrors.description}</FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} lg={3}>
                <FormGroup check className="mr-3">
                  <Input
                    type="checkbox"
                    checked={hasAccessForlegis}
                    onChange={() => setHasAccessForlegis((prev) => !prev)}
                  />
                  <Label check>Forlegis</Label>
                </FormGroup>
              </Col>
              <Col xs={12} sm={6} lg={3}>
                <FormGroup check className="mr-3">
                  <Input
                    type="checkbox"
                    checked={hasAccessListContacts}
                    onChange={() => setHasAccessListContacts((prev) => !prev)}
                  />
                  <Label check>Lista de Contatos</Label>
                </FormGroup>
              </Col>
              <Col xs={12} sm={6} lg={3}>
                <FormGroup check className="mr-3">
                  <Input
                    type="checkbox"
                    checked={hasAccessApp}
                    onChange={() => setHasAccessApp((prev) => !prev)}
                  />
                  <Label check>App</Label>
                </FormGroup>
              </Col>
              <Col xs={12} sm={6} lg={3}>
                <FormGroup check className="mr-3">
                  <Input
                    type="checkbox"
                    checked={hasAccessPortal}
                    onChange={() => setHasAccessPortal((prev) => !prev)}
                  />
                  <Label check>Portal</Label>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleSave}>Salvar</Button>
          </ModalFooter>
        </Modal>
      </Container>
    </LayoutAdmin>
  );
}

export default Roles;
