import React from 'react';
import { Card, CardBody, CardHeader, CardText, CardTitle, Col, Container, Progress, Row } from 'reactstrap';

import sendemail from '../../assets/img/send_email.gif';
import useEmailLogStatusListerner from './useEmailLogStatusListerner';

function EmailLogStatus({ emailId, sendingEmail, onEndSendEmail }) {
  const {
    countSuccess,
    countError,
    total,
    lastMessage,
  } = useEmailLogStatusListerner(emailId, sendingEmail, onEndSendEmail);

  return (
    <Container>
      <Row>
        <Col>
          <div className="d-flex flex-column align-items-center">
            <h1>Enviando emails...</h1>
            <img src={sendemail} alt="Enviando emails" />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>{lastMessage}</p>
          <Progress
            className="my-2"
            animated
            multi
          >
            <Progress
              bar
              color="success"
              value={countSuccess > 0 ? parseInt(Math.round((countSuccess * 100) / total), 10) : 0}
              style={{
                height: '20px',
              }}
            >
              {countSuccess}
            </Progress>
            <Progress
              bar
              color="danger"
              value={countError > 0 ? parseInt(Math.round((countError * 100) / total), 10) : 0}
              style={{
                height: '20px',
              }}
            >
              {countError}
            </Progress>
          </Progress>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <Card
              className="my-2"
              color="success"
              inverse
              style={{
                width: '18rem',
              }}
            >
              <CardHeader>
                Email Enviado com Sucesso
              </CardHeader>
              <CardBody>
                <CardTitle tag="h5">
                  Quantidade
                </CardTitle>
                <CardText>
                  { countSuccess }
                </CardText>
              </CardBody>
            </Card>
            <Card
              className="my-2"
              color="danger"
              inverse
              style={{
                width: '18rem',
              }}
            >
              <CardHeader>
                Falha no Envio
              </CardHeader>
              <CardBody>
                <CardTitle tag="h5">
                  Quantidade
                </CardTitle>
                <CardText>
                  { countError }
                </CardText>
              </CardBody>
            </Card>
            <Card
              className="my-2"
              color="dark"
              inverse
              style={{
                width: '18rem',
              }}
            >
              <CardHeader>
                Total de Emails
              </CardHeader>
              <CardBody>
                <CardTitle tag="h5">
                  Quantidade
                </CardTitle>
                <CardText>
                  { total }
                </CardText>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default EmailLogStatus;
