import React, {
  useState,
  useEffect,
  useRef,
  createContext,
  useContext,
  useMemo,
} from 'react';

import Notification from '../components/Notification';
import { ID_KEY, ID_KEY_ADMIN, TOKEN_KEY, TOKEN_KEY_ADMIN, USER_NAME, USER_NAME_ADMIN } from '../constants/auth';
import api from '../services/api';

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [loggedUser, setLoggedUser] = useState({});
  const [userToken, setUserToken] = useState(null);
  const [userTokenAdmin, setUserTokenAdmin] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticatedAdmin, setIsAuthenticatedAdmin] = useState(false);
  const notificationRef = useRef();

  useEffect(() => {
    if (!userToken) {
      setIsAuthenticated(false);
      return;
    }

    (async () => {
      try {
        const { data } = await api.get('/getUsers');
        if (data) {
          setIsAuthenticated(true);
        }
        setLoggedUser(data);
      } catch (error) {
        console.log(error);
        setIsAuthenticated(false);
        if (typeof error === 'string') {
          notificationRef.current.notify({
            message: error,
            color: 'danger',
          });
          return;
        }

        if (error && error.response) {
          const { message } = error.response.data;
          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });
          }
        }
      }
    })();
  }, [userToken]);

  useEffect(() => {
    // console.log(userTokenAdmin);
    if (!userTokenAdmin) {
      setIsAuthenticatedAdmin(false);
      return;
    }

    (async () => {
      try {
        const { data } = await api.get('/getAdmins');
        // console.log(data);
        if (data) {
          setIsAuthenticatedAdmin(true);
        }
        setLoggedUser(data);
      } catch (error) {
        console.log(error);
        setIsAuthenticatedAdmin(false);
        if (typeof error === 'string') {
          notificationRef.current.notify({
            message: error,
            color: 'danger',
          });
          return;
        }

        if (error && error.response) {
          const { message } = error.response.data;
          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });
          }
        }
      }
    })();
  }, [userTokenAdmin]);

  const value = useMemo(
    () => ({
      isAuthenticated,
      isAuthenticatedAdmin,
      loggedUser,
      setLoggedUser,
      signIn: (token, user) => {
        localStorage.removeItem(TOKEN_KEY_ADMIN);
        localStorage.removeItem(ID_KEY_ADMIN);
        localStorage.removeItem(USER_NAME_ADMIN);
        localStorage.setItem(TOKEN_KEY, token);
        localStorage.setItem(ID_KEY, user.id || user._id);
        localStorage.setItem(USER_NAME, user.name);
        setIsAuthenticatedAdmin(false);
        setIsAuthenticated(true);
        setUserTokenAdmin(null);
        setUserToken(token);
      },
      signInAdmin: (token, user) => {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(ID_KEY);
        localStorage.removeItem(USER_NAME);
        localStorage.setItem(TOKEN_KEY_ADMIN, token);
        localStorage.setItem(ID_KEY_ADMIN, user.id || user._id);
        localStorage.setItem(USER_NAME_ADMIN, user.name);
        setIsAuthenticated(false);
        setIsAuthenticatedAdmin(true);
        setUserToken(null);
        setUserTokenAdmin(token);
      },
      signOut: async () => {
        console.log('sign out');
        await api.delete('/logoutUsers');
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(ID_KEY);
        localStorage.removeItem(USER_NAME);
        localStorage.removeItem(TOKEN_KEY_ADMIN);
        localStorage.removeItem(ID_KEY_ADMIN);
        localStorage.removeItem(USER_NAME_ADMIN);
        setLoggedUser(null);
        setIsAuthenticated(false);
        setUserToken(null);
      },
      signOutAdmin: async () => {
        console.log('sign out');
        await api.delete('/logoutAdmins');
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(ID_KEY);
        localStorage.removeItem(USER_NAME);
        localStorage.removeItem(TOKEN_KEY_ADMIN);
        localStorage.removeItem(ID_KEY_ADMIN);
        localStorage.removeItem(USER_NAME_ADMIN);
        setLoggedUser(null);
        setIsAuthenticatedAdmin(false);
        setUserTokenAdmin(null);
      },
    }),
    [userToken, userTokenAdmin, isAuthenticated, isAuthenticatedAdmin, loggedUser],
  );

  useEffect(() => {
    const tokenUser = localStorage.getItem(TOKEN_KEY);

    if (tokenUser) {
      setIsAuthenticated(true);
      setUserToken(tokenUser);
    } else {
      const tokenAdmin = localStorage.getItem(TOKEN_KEY_ADMIN);
      if (tokenAdmin) {
        setIsAuthenticatedAdmin(true);
        setUserTokenAdmin(tokenAdmin);
      }
    }
  }, []);

  return (
    <AuthContext.Provider value={value}>
      <Notification ref={notificationRef} />
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};

export default AuthProvider;
