/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import RichTextEditor from 'react-rte';
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  FormGroup,
  Input,
  FormFeedback,
  Label,
  CardFooter,
  Button,
} from 'reactstrap';

import HTMLReactParser from 'html-react-parser';
import * as Yup from 'yup';

import DatePickerMaskedInput from '../../components/DatePickerMaskedInput';
import GoToTop from '../../components/GoToTop';
import Notification from '../../components/Notification';
import TimePickerMaskedInput from '../../components/TimePickerMaskedInput';
import { AppContext } from '../../contexts/app';
import LayoutAdmin from '../../layouts/admin';
import api from '../../services/api';

export default function PaginaNew() {
  const notificationRef = useRef();
  const navigate = useNavigate();
  const params = useParams();
  const { setLoading } = useContext(AppContext);
  const [validationErrors, setValidationErrors] = useState({});
  const [menu, setMenu] = useState({});
  const [submenu, setSubmenu] = useState({});
  const [titulo, setTitulo] = useState('');
  const [autor, setAutor] = useState('');
  const [situacao, setSituacao] = useState('');
  const [date, setDate] = useState(new Date());
  const [hour, setHour] = useState(new Date());
  const [texto, setTexto] = useState(RichTextEditor.createEmptyValue());

  useEffect(() => {
    (async () => {
      try {
        const { data = {} } = await api.get(`/menus/${params.menu}`);
        setMenu(data);
        setSubmenu(data?.submenus?.find((s) => s.id == params.id));
      } catch (error) {
        console.log(error);
      }
    })();
  }, [params]);

  const situacoes = useMemo(() => ['', 'Em Andamento', 'Aprovado']);

  const handleSubmit = async () => {
    try {
      const data = {
        idmenu: menu.id,
        idsubmenu: submenu.id,
        titulo,
        autor,
        situacao,
        date,
        hour,
        texto: texto ? texto.toString('html').replace('<p><br></p>', '') : '',
      };

      const schema = Yup.object().shape({
        titulo: Yup.string().required('Informe o título da página'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.post('/paginas', data);

      notificationRef.current.notify({
        message: 'Página cadastrada com sucesso',
      });

      setTimeout(() => {
        navigate(`/admin/exibicao/${params.exibicao}/menu/${menu.id}/submenus/${submenu.id}/cadastro/${response.data.id}`);
      }, 2000);
    } catch (err) {
      setLoading(false);
      console.log(err);
      setValidationErrors({});
      // Validation failed
      if (err instanceof Yup.ValidationError) {
        notificationRef.current.notify({
          message: 'Por favor, preencha todos os dados necessários para salvar',
          color: 'warning',
        });
        const ve = {};
        err.inner.forEach((e) => {
          ve[e.path] = e.message;
        });
        setValidationErrors(ve);
        return;
      }

      if (err && err.response && err.response.data) {
        const { message } = err.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });

        return;
      }

      notificationRef.current.notify({
        message: 'Algo inesperado aconteceu e não foi possível salvar',
        color: 'danger',
      });
    }
  };

  return (
    <LayoutAdmin>
      <Notification ref={notificationRef} />
      <Container>
        <GoToTop />
        <Row>
          <Col>
            <Card className="mt-5">
              <CardHeader>
                <div className="d-flex flex-column justify-content-center">
                  <h1 className="font-weight-900 text-primary text-center">Cadastrar</h1>
                  <h5 className="font-weight-900 text-primary text-center">
                    {HTMLReactParser(`<span className="text-center">${submenu?.nome}</span>`)}
                  </h5>
                </div>
              </CardHeader>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Título</Label>
                      <Input
                        type="text"
                        placeholder="Título"
                        value={titulo}
                        invalid={!!validationErrors.titulo}
                        onChange={({ target: { value } }) => setTitulo(value)}
                      />
                      <FormFeedback>{validationErrors.titulo}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Autor (opcional)</Label>
                      <Input
                        type="text"
                        placeholder="Autor"
                        value={autor}
                        onChange={({ target: { value } }) => setAutor(value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Situação (opcional)</Label>
                      <Input
                        type="select"
                        placeholder="Situação"
                        value={situacao}
                        onChange={({ target: { value } }) => setSituacao(value)}
                      >
                        {situacoes.map((s) => (
                          <option value={s} key={s}>
                            {s}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={8}>
                    <DatePickerMaskedInput
                      title="Data (opcional)"
                      label="Data (opcional)"
                      selected={date}
                      onChange={(d) => setDate(d)}
                    />
                  </Col>
                  <Col xs={12} sm={4}>
                    <TimePickerMaskedInput
                      title="Hora (opcional)"
                      label="Hora (opcional)"
                      selected={hour}
                      onChange={(d) => setHour(d)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Texto (opcional)</Label>
                      <RichTextEditor
                        className="text-dark"
                        value={texto}
                        onChange={setTexto}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button color="success" onClick={handleSubmit}>
                  Salvar e Continuar
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </LayoutAdmin>
  );
}
