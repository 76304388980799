import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Button, Row, Progress, Container, Col, UncontrolledTooltip } from 'reactstrap';

import truncateWithEllipses from '../../../helpers/TruncateStringWithEllipses';

function FileList({ files, onDelete }) {
  return (
    <Container className="py-2">
      {files.map(
        (uploadedFile) => uploadedFile && (
        <Row
          key={uploadedFile.id}
          className="align-items-center my-2 p-2"
          style={{ borderTop: '1px solid var(--gray)' }}
        >
          {uploadedFile.preview ? (
            <Col sm={1}>
              <div
                style={{
                  width: '36px',
                  height: '36px',
                  borderRadius: '5px',
                  backgroundImage: `url(${uploadedFile.preview})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: '50% 50%',
                  marginRight: '10px',
                }}
              />
            </Col>
          ) : null}
          <Col>
            <Row key={uploadedFile.id}>
              <Col xs={10} md={9}>
                <strong className="mr-2 text-truncate">
                  {isMobileOnly
                    ? truncateWithEllipses(uploadedFile.name, 22)
                    : truncateWithEllipses(uploadedFile.name, 30)}
                </strong>
              </Col>
              <Col xs={2} md={1}>
                {uploadedFile.url && (
                <a
                  href={uploadedFile.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <UncontrolledTooltip target="link-id">
                    Ver Arquivo
                  </UncontrolledTooltip>
                  <i id="link-id" className="fas fa-link text-warning" />
                </a>
                )}
              </Col>
              <Col xs={12} md={2}>
                <span>
                  {uploadedFile.id && uploadedFile.uploaded && onDelete && (
                  <>
                    <UncontrolledTooltip target="delete-id">
                      Excluir
                    </UncontrolledTooltip>
                    <Button
                      id="delete-id"
                      color="danger"
                      size="sm"
                      onClick={() => onDelete(uploadedFile.id)}
                      block
                    >
                      <i className="fas fa-trash" />
                    </Button>
                  </>
                  )}
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                {uploadedFile.error && (
                <small className="text-danger">
                  <i className="fas fa-exclamation-circle mr-1" />
                  Erro no upload. Tente enviar novamente.
                </small>
                )}

                {!uploadedFile.error && !uploadedFile.uploaded && (
                <div className="w-100">
                  <small className="text-info mr-3">
                    <i className="fas fa-file-upload mr-1" />
                    Fazendo upload do arquivo
                  </small>
                  {uploadedFile.progress ? (
                    <Progress
                      color="info"
                      value={uploadedFile.progress}
                      style={{ height: '0.5rem' }}
                    />
                  ) : null}
                </div>
                )}

                {uploadedFile.uploaded && (
                <small className="text-success">
                  <i className="fas fa-check-circle mr-1" />
                  Upload concluido com sucesso
                </small>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        ),
      )}
    </Container>
  );
}

export default FileList;
