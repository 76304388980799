import React, { useEffect, useCallback, useRef, useMemo } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from 'reactstrap';

import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import Notification from '../../components/Notification';
import TableManager from '../../components/TableManager';
import { useManager } from '../../hooks/manager';
import LayoutAdmin from '../../layouts/admin';

function UsersRenunciados() {
  const notificationRef = useRef();
  const { load, destroy } = useManager();

  useEffect(() => {
    load('/renuncias');
  }, [load]);

  const dataInicioFormatter = useCallback((row) => (
    row.data ? (
      <span>
        {format(new Date(row.data), 'dd/MM/yyyy', { locale: ptBR })}
      </span>
    ) : (
      <span />
    )
  ), []);

  const columns = useMemo(() => [
    {
      dataField: 'user.name',
      text: 'Nome',
      textFilter: true,
    },
    {
      dataField: 'user.cim',
      text: 'CIM',
      textFilter: true,
    },
    {
      dataField: 'data',
      text: 'Data Início',
      formatter: dataInicioFormatter,
    },
    {
      dataField: 'observacao',
      text: 'Observação',
      textFilter: true,
    },
  ], []);

  return (
    <LayoutAdmin>
      <Notification ref={notificationRef} />
      <Container>
        <Row className="mt-5">
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h3>Usuário Renunciados</h3>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableManager
              columns={columns}
              onDelete={(row) => destroy(row.id, '/renuncias')}
            />
          </Col>
        </Row>
      </Container>
    </LayoutAdmin>
  );
}

export default UsersRenunciados;
