import React from 'react';

import Content from './Content';
import navigation from './navigation';
import Sidebar from './Sidebar';

// import { Container } from './styles';

function layout({ children }) {
  return (
    <div className="d-flex" style={{ height: '130vh' }}>
      <Sidebar items={navigation} />
      <Content>
        {children}
      </Content>
    </div>
  );
}

export default layout;
