import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';

import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import HTMLReactParser from 'html-react-parser';

import EmailLogStatus from '../../components/EmailLogStatus';
import Notification from '../../components/Notification';
import TableManager from '../../components/TableManager';
import { useManager } from '../../hooks/manager';
import LayoutAdmin from '../../layouts/admin';
import api from '../../services/api';

function EmailLog() {
  const params = useParams();
  const notificationRef = useRef();
  const { load } = useManager();
  const [email, setEmail] = useState();
  const [sendingEmail, setSendingEmail] = useState(false);

  useEffect(() => {
    if (params?.id) {
      load(`/emailLogs/${params.id}`);
      (async () => {
        try {
          const { data } = await api.get(`/emails/${params.id}`);
          setEmail(data);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [load, params]);

  const dataFormatter = useCallback((row) => (
    row.created_at ? (
      <span>
        {format(new Date(row.created_at), 'dd/MM/yyyy HH:mm:ss', { locale: ptBR })}
      </span>
    ) : (
      <span />
    )
  ), []);

  const enviadoFormatter = useCallback(
    (row) => (row?.enviado ? (
      <>
        <UncontrolledTooltip target={`enviado-${row?.id}`}>
          Email Enviado
        </UncontrolledTooltip>
        <i
          id={`enviado-${row?.id}`}
          className="fas fa-check text-success mt-2"
        />
      </>
    ) : (
      <>
        <UncontrolledTooltip target={`enviado-${row?.id}`}>
          Email não Enviado
        </UncontrolledTooltip>
        <i
          id={`enviado-${row?.id}`}
          className="fas fa-times text-danger mt-2"
        />
      </>
    )),
    [],
  );

  const statusFormatter = useCallback((row) => {
    if (row?.status === 'success') {
      return (
        <>
          <UncontrolledTooltip target={`status-${row?.id}`}>
            Enviado com sucesso
          </UncontrolledTooltip>
          <i
            id={`status-${row?.id}`}
            className="fa-solid fa-circle text-success mt-2"
          />
        </>
      );
    }

    if (row?.status === 'error') {
      return (
        <>
          <UncontrolledTooltip target={`status-${row?.id}`}>
            Falha na Tentiva de Envio do email
          </UncontrolledTooltip>
          <i
            id={`status-${row?.id}`}
            className="fa-solid fa-circle text-danger mt-2"
          />
        </>
      );
    }

    if (row?.status === 'softbounce') {
      return (
        <>
          <UncontrolledTooltip target={`status-${row?.id}`}>
            Devolução Temporária
          </UncontrolledTooltip>
          <i
            id={`status-${row?.id}`}
            className="fa-solid fa-arrow-rotate-right text-warning mt-2"
          />
        </>
      );
    }

    if (row?.status === 'hardbounce') {
      return (
        <>
          <UncontrolledTooltip target={`status-${row?.id}`}>
            Devolução Permanente
          </UncontrolledTooltip>
          <i
            id={`status-${row?.id}`}
            className="fa-solid fa-circle-xmark text-danger mt-2"
          />
        </>
      );
    }

    return (
      <>
        <UncontrolledTooltip target={`status-${row?.id}`}>
          Sem Informações
        </UncontrolledTooltip>
        <i
          id={`status-${row?.id}`}
          className="fa-solid fa-window-minimize text-info mt-2"
        />
      </>
    );
  }, []);

  const messageFormatter = useCallback((row) => {
    if (row?.message) {
      return (
        <>
          <UncontrolledTooltip target={`message-${row?.id}`}>
            {row?.message}
          </UncontrolledTooltip>
          <i
            id={`message-${row?.id}`}
            className="fa-solid fa-circle-info text-info mt-2"
          />
        </>
      );
    }

    return (
      <>
        <UncontrolledTooltip target={`message-${row?.id}`}>
          Indefinido
        </UncontrolledTooltip>
        <i
          id={`message-${row?.id}`}
          className="fa-solid fa-window-minimize text-info mt-2"
        />
      </>
    );
  }, []);

  const enviadoSelectFilter = useMemo(
    () => [
      {
        value: true,
        label: 'Enviado',
      },
      {
        value: false,
        label: 'Não Enviado',
      },
    ],
    [],
  );

  const statusSelectFilter = useMemo(
    () => [
      {
        value: 'softbounce',
        label: 'Devolução Temporária',
      },
      {
        value: 'hardbounce',
        label: 'Devolução Permanente',
      },
      {
        value: 'success',
        label: 'Enviado com sucesso',
      },
      {
        value: 'error',
        label: 'Erro no Envio',
      },
    ],
    [],
  );

  const columns = useMemo(
    () => [
      {
        dataField: 'user_name',
        text: 'Deputado',
        textFilter: true,
      },
      {
        dataField: 'user_email',
        text: 'Email',
        textFilter: true,
      },
      {
        dataField: 'enviado',
        text: 'Enviado',
        formatter: enviadoFormatter,
        selectFilter: enviadoSelectFilter,
        align: 'center',
      },
      {
        dataField: 'status',
        text: 'Status',
        formatter: statusFormatter,
        selectFilter: statusSelectFilter,
        align: 'center',
      },
      {
        dataField: 'message',
        text: 'Mensagem',
        formatter: messageFormatter,
        align: 'center',
      },
      {
        dataField: 'created_at',
        text: 'Data',
        formatter: dataFormatter,
      },
    ],
    [],
  );

  const handleEndSendEmail = useCallback(() => {
    notificationRef.current.notify({
      message: 'Emails enviados',
    });
    load(`/emailLogs/${params.id}`);
    setTimeout(() => {
      setSendingEmail(false);
    }, 5000);
  }, []);

  const handleSubmit = async () => {
    try {
      setSendingEmail(true);
      await api.get(`/reSendEmails/${params.id}`);
    } catch (err) {
      setSendingEmail(false);
      console.log(err);

      if (err && err.response && err.response.data) {
        const { message } = err.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });

        return;
      }

      notificationRef.current.notify({
        message: 'Algo inesperado aconteceu e não foi possível enviar o email',
        color: 'danger',
      });
    }
  };

  return (
    <LayoutAdmin>
      <Container>
        <Notification ref={notificationRef} />
        <Row className="mt-5">
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h3>Email</h3>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h5>
                      {`Estado: ${email?.uf ? email?.uf : 'Não especificado'}`}
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h5>{`Título: ${email?.subject ? email.subject : ''}`}</h5>
                  </Col>
                </Row>
                {email?.message && (
                  <Row>
                    <Col>
                      <h5>Mensagem:</h5>
                      {HTMLReactParser(email.message)}
                    </Col>
                  </Row>
                )}
                {email?.attachments && (
                  <Row>
                    <Col>
                      <h5>Anexo(s):</h5>
                      {HTMLReactParser(email.attachments)}
                    </Col>
                  </Row>
                )}
              </CardBody>
              <CardFooter>
                <Row>
                  <Col>
                    <Row>
                      <Col className="mb-3 mb-xl-0">
                        <Button color="success" onClick={handleSubmit} disabled={sendingEmail}>
                          Reenviar email para os não enviados
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        {sendingEmail ? (
          <Row>
            <Col>
              <EmailLogStatus
                emailId={params.id}
                sendingEmail={sendingEmail}
                onEndSendEmail={handleEndSendEmail}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              <TableManager columns={columns} />
            </Col>
          </Row>
        )}
      </Container>
    </LayoutAdmin>
  );
}

export default EmailLog;
