/* eslint-disable no-nested-ternary */
import React from 'react';
import Dropzone from 'react-dropzone';

export default function Upload({
  accept = 'image/*',
  message: Message = () => (
    <p style={{ color: 'var(--warning)' }}>
      Selecione ou Arraste os arquivos aqui...
    </p>
  ),
  messageReject = 'Arquivo não suportado',
  messageAccept = 'Solte os arquivos aqui',
  height = '100px',
  onUpload,
  ...props
}) {
  const renderDragMessage = (isDragActive, isDragReject) => {
    if (!isDragActive) {
      return (
        <Message />
      );
    }

    if (isDragReject) {
      return <p style={{ color: 'var(--danger)' }}>{messageReject}</p>;
    }

    return (
      <p
        className="d-flex justify-content-center align-items-center py-3"
        style={{ color: 'var(--success)' }}
      >
        {messageAccept}
      </p>
    );
  };

  return (
    <Dropzone accept={accept} onDropAccepted={onUpload} {...props}>
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <div
          {...getRootProps()}
          className="dropzone w-100"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: `1px dashed ${
              !isDragActive
                ? 'var(--warning)'
                : isDragReject
                  ? 'var(--danger)'
                  : 'var(--success)'
            }`,
            height,
            // borderRadius: '0.75rem',
            cursor: 'pointer',
            transition: 'height 0.2s ease',
          }}
        >
          <input {...getInputProps()} />
          {renderDragMessage(isDragActive, isDragReject)}
        </div>
      )}
    </Dropzone>
  );
}
