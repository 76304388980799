import React from 'react';

const Login = React.lazy(() => import('../pages/Login'));
const LoginAdmin = React.lazy(() => import('../pages/LoginAdmin'));

export default [
  { path: '/', Component: Login },
  { path: '/login', Component: Login },
  { path: '/login-admin', Component: LoginAdmin },
  { path: '*', Component: Login },
];
