import React from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import InputMask from 'react-input-mask';
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import { getMonth, getYear } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { range } from 'lodash';

import './styles.css';

registerLocale('ptBR', ptBR);
setDefaultLocale('ptBR');

function DatePickerMaskedInput({
  label,
  value,
  selected,
  title = 'Data',
  inputSize = 'md',
  placeholderText = 'dd/mm/aaaa',
  dateFormat = 'dd/MM/yyyy',
  locale = 'ptBR',
  invalidMessage = '',
  isClearable = true,
  yearsRange = range(1900, getYear(new Date()) + 100, 1),
  onChange,
  onBlurInput,
  withFormGroup = true,
  ...props
}) {
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  return (
    <DatePicker
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            color="link"
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
          >
            <i className="fas fa-arrow-circle-left" />
          </Button>

          <Input
            className="mr-2"
            type="select"
            value={months[getMonth(date)]}
            onChange={({ target: { value: v } }) => changeMonth(months.indexOf(v))}
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Input>

          <Input
            type="select"
            value={getYear(date)}
            onChange={({ target: { value: v } }) => changeYear(v)}
          >
            {yearsRange.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Input>

          <Button
            color="link"
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          >
            <i className="fas fa-arrow-circle-right" />
          </Button>
        </div>
      )}
      title={title}
      placeholderText={placeholderText}
      dateFormat={dateFormat}
      locale={locale}
      selected={selected}
      value={value}
      onChange={onChange}
      isClearable={isClearable}
      {...props}
      customInput={(
        <InputMask
          mask="99/99/9999"
          placeholder="dd/mm/aaaa"
        >
          {(inputProps) => {
            if (withFormGroup) {
              return (
                <FormGroup>
                  {label ? (
                    <Label for={`date-picker-${props.id}`}>{label}</Label>
                  ) : null}
                  <Input
                    id={`date-picker-${props.id}`}
                    className="w-100"
                    bsSize={inputSize}
                    invalid={!!invalidMessage}
                    onBlur={onBlurInput}
                    {...inputProps}
                  />
                  <FormFeedback className="text-danger">
                    {invalidMessage}
                  </FormFeedback>
                </FormGroup>
              );
            }

            return (
              <Input
                id={`date-picker-${props.id}`}
                className="w-100"
                bsSize={inputSize}
                invalid={!!invalidMessage}
                {...inputProps}
              />
            );
          }}
        </InputMask>
      )}
    />
  );
}

export default DatePickerMaskedInput;
