/* eslint-disable camelcase */
import { useState, useEffect } from 'react';

import api from '../../services/api';

const useEmailLogStatusListerner = (emailId, sendingEmail, onEndSendEmail) => {
  const [data, setData] = useState();

  useEffect(() => {
    let timer;
    if (emailId && sendingEmail) {
      timer = setInterval(async () => {
        const res = await api.get(`/emailLogStatus/${emailId}`);
        const { count_success, count_error, total } = res.data;
        console.log('🚀 ~ timer ~ data:', res.data);
        setData(res.data);

        if (count_success + count_error === total) {
          if (typeof onEndSendEmail === 'function') {
            onEndSendEmail();
          }
          clearInterval(timer);
        }
      }, 3000);
    }
    return () => timer && clearInterval(timer);
  }, [emailId]);

  return {
    countSuccess: data?.count_success || 0,
    countError: data?.count_error || 0,
    total: data?.total || 0,
    lastMessage: data?.last_message || 'Iniciando envio de emails',
  };
};

export default useEmailLogStatusListerner;
