/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';

import Notification from '../../components/Notification';
import TableManager from '../../components/TableManager';
import { useManager } from '../../hooks/manager';
import LayoutAdmin from '../../layouts/admin';

function UserDevice() {
  const notificationRef = useRef();
  const { load, update } = useManager();

  useEffect(() => {
    load('/dispositivos');
  }, [load]);

  const ativoFormatter = useCallback((row) => (row?.ativo ? (
    <>
      <UncontrolledTooltip target={`ativo-${row?.id}`}>
        Ativo
      </UncontrolledTooltip>
      <i
        id={`ativo-${row?.id}`}
        className="fas fa-check text-success mt-2"
      />
    </>
  ) : (
    <>
      <UncontrolledTooltip target={`ativo-${row?.id}`}>
        Não ativo
      </UncontrolledTooltip>
      <i
        id={`ativo-${row?.id}`}
        className="fas fa-times text-danger mt-2"
      />
    </>
  )), []);

  const ativoSelectFilter = useMemo(
    () => [
      {
        value: true,
        label: 'Ativos',
      },
      {
        value: false,
        label: 'Não Ativos',
      },
    ],
    [],
  );

  const columns = useMemo(
    () => [
      {
        dataField: 'cim',
        text: 'CIM',
        textFilter: true,
      },
      {
        dataField: 'modelo',
        text: 'Modelo',
        textFilter: true,
      },
      {
        dataField: 'plataforma',
        text: 'Plataforma',
        textFilter: true,
      },
      {
        dataField: 'ativo',
        text: 'Ativo',
        formatter: ativoFormatter,
        selectFilter: ativoSelectFilter,
      },
    ],
    [],
  );

  const actions = useMemo(
    () => [
      {
        name: 'ativo',
        tooltip: (row) => (row?.ativo ? 'Desativar' : 'Ativo'),
        color: (row) => (row?.ativo ? 'danger' : 'success'),
        className: (row) => (row?.ativo ? 'mt-2 mr-2 text-white' : 'd-none'),
        icon: (row) => (row?.ativo ? <i className="fa-solid fa-circle-xmark" /> : <i className="fa-solid fa-circle-check" />),
        onClick: (row) => update(row?.id, '/dispositivos', { }),
      },
    ],
    [],
  );

  return (
    <LayoutAdmin>
      <Notification ref={notificationRef} />
      <Container>
        <Row className="mt-5">
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h3>Dispositivos</h3>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <TableManager
              columns={columns}
              actions={actions}
            />
          </Col>
        </Row>
      </Container>
    </LayoutAdmin>
  );
}

export default UserDevice;
