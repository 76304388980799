import React from 'react';
import Select from 'react-select';

function SelectComponent({
  placeholder,
  value,
  options,
  loading,
  invalidMessage,
  onChange,
  isClearable = true,
  ...props
}) {
  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: '#fff',
      borderRadius: '0.25rem',
      padding: '0.05rem',
      fontSize: '1rem',
      borderColor: invalidMessage ? '#dc3545' : '#ced4da',
    }),
    input: (styles) => ({
      ...styles,
      color: '#343a40',
    }),
    option: (styles) => ({
      ...styles,
      cursor: 'default',
      color: '#343a40',

      ':active': {
        ...styles[':active'],
        color: '#fff',
        backgroundColor: '#475993',
      },

      ':hover': {
        ...styles[':hover'],
        color: '#fff',
        backgroundColor: '#475993',
      },
    }),
    menu: (styles) => ({
      ...styles,
    }),
  };

  return (
    <Select
      className="basic-single mb-3"
      classNamePrefix="select"
      placeholder={(
        <div
          className={
            invalidMessage
              ? 'react-select__placeholder_error'
              : 'react-select__placeholder'
          }
        >
          {invalidMessage || placeholder}
        </div>
      )}
      noOptionsMessage={() => 'Nenhum valor encontrado'}
      loadingMessage={() => 'Carregando'}
      isLoading={loading}
      isClearable={isClearable}
      isSearchable
      value={value}
      options={options}
      styles={selectStyles}
      onChange={onChange}
      {...props}
    />
  );
}

export default SelectComponent;
